class SettingsService {
	#data = {
		trackingEnabled: true,
	};
	#key = 'ard-settings';

	constructor() {
		this.#data = { ...this.#data, ...this.#getStoredSettings() };

		this.#storeData();
	}

	get trackingEnabled(): boolean {
		return this.#data.trackingEnabled;
	}

	set trackingEnabled(value: boolean) {
		this.#data.trackingEnabled = value;

		this.#storeData();
	}

	#getStoredSettings() {
		const storedSettings = window.localStorage.getItem(this.#key);
		return JSON.parse(storedSettings || '{}');
	}

	#storeData() {
		window.localStorage.setItem(this.#key, JSON.stringify(this.#data));
	}
}

const settingsService = new SettingsService();
export default settingsService;
