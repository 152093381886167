import React, { useEffect, useState } from 'react';
import HeaderWidget from '../../widgets/header/HeaderWidget';
import { PageWrapper } from '../../app/styles';
import { List, Listitem, Root } from './styles';
import { Column, Row, Switch, Typo } from '@ard-online/component-styleguide';
import settingsService from '../../../services/settings/SettingsService';
import { sendPageImpression } from '../../../services/tracking';
import { PAGE_TITLES } from '../../../configs/types';
import { cx } from '@linaria/core';

function SettingsPage() {
	/** Variables */
	const [trackingEnabled, setTrackingEnabled] = useState(() => settingsService.trackingEnabled);

	function toggleTracking() {
		settingsService.trackingEnabled = !settingsService.trackingEnabled;
		setTrackingEnabled((prevState: boolean) => !prevState);
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		sendPageImpression(PAGE_TITLES.SETTINGS);
	}, []);

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<HeaderWidget />
			<main className={cx(PageWrapper.base)}>
				<h1 className={cx(Typo.base, Typo.heading01)}>{PAGE_TITLES.SETTINGS}</h1>
				<Row justifyContent="center">
					<Column xs={12} l={8} xl={8}>
						<h2 className={cx(Typo.base, Typo.heading06)}>Datenschutz</h2>
						<ul className={cx(List.base)}>
							<li className={cx(Listitem.base)}>
								<p className={cx(Typo.base, Typo.body02)}>
									Anonyme Nutzungsstatistiken (Zählpixel) übertragen
								</p>
								<Switch
									isEnabled={trackingEnabled}
									onClick={toggleTracking}
									label="Anonyme Nutzungsstatistiken (Zählpixel) übertragen"
								/>
							</li>
						</ul>
					</Column>
				</Row>
			</main>
		</div>
	);
}

export default SettingsPage;
