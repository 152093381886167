import { useEffect, useState } from 'react';
import type { AnyFunction } from '../../types';
import { XMLParser } from 'fast-xml-parser';
import fetchOptions from './fetchOptions';
export const decodeEntities = (tagName: string, tagValue: string) => {
	return tagValue.replace(/&amp;/g, '&');
};

function useFetch(url: string, type: string, mapToData?: AnyFunction, widgetIndex?: number, id?: string) {
	const [data, setData] = useState([]);
	const apiFetchOptions = url.includes('api.ardmediathek.de') ? fetchOptions : undefined;

	async function fetchJSON(url: string) {
		try {
			const res = await fetch(url, apiFetchOptions);

			if (!res.ok) {
				console.error(res.status, url);
				return;
			}

			const json = await res.json();

			if (mapToData) {
				const mappedData = mapToData(json);
				setData(mappedData);
			} else {
				setData(json);
			}
		} catch (error) {
			console.error(error, url);
		}
	}
	async function fetchXML(url: string) {
		try {
			const res = await fetch(url, apiFetchOptions);

			if (!res.ok) {
				console.error(res.status, url);
				return;
			}

			const xml = await res.text();
			const parser = new XMLParser({
				ignoreAttributes: true,
				removeNSPrefix: true,
				parseTagValue: false,
				trimValues: true,
				tagValueProcessor: decodeEntities,
			});

			const jsonData = parser.parse(xml);

			if (mapToData) {
				const mappedData = mapToData(jsonData, id);
				setData(mappedData);
			}
		} catch (error) {
			console.error(error, url);
		}
	}
	useEffect(() => {
		if (type === 'json') {
			fetchJSON(url);
		} else if (type === 'xml') {
			fetchXML(url);
		}
	}, [url, type, widgetIndex, id]);
	return data;
}

export default useFetch;
