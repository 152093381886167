import { PRODUCT_NAME } from '../../configs/strings';
import { createAtiTag } from './createAtiTag';
import { encodeForTracking, mapPageTitleToChapterOne, sendOfflineTracking } from './misc';
import { getViewSize } from '../viewSize';
import { isMobileDevice, isPhoneDevice } from './deviceType';
import settingsService from '../settings/SettingsService';
import { AnyObject } from '../../types';
import { PAGE_TITLES } from '../../configs/types';

export default function sendPageImpression(pageTitle: PAGE_TITLES) {
	if (settingsService.trackingEnabled) {
		const tag = createAtiTag();

		if (tag) {
			const name = `${PRODUCT_NAME}/${pageTitle}`;
			tag.page.set({
				level2: '1',
				name: encodeForTracking(name),
				chapter1: mapPageTitleToChapterOne(pageTitle),
			});
			const customSiteVars: AnyObject = {
				// semantics from https://software.swr.cn.ard.de/confluence/pages/viewpage.action?spaceKey=ARDOI&title=Pixel-Konzept
				'1': encodeForTracking(name),
				//titel
				'2': encodeURIComponent(window.location.href),
				//url
				'4': isMobileDevice() ? 1 : 0,
				// ausspielweg
				'11': getViewSize(),
				//responsiveview
				'12': isPhoneDevice() ? 'MEW' : 'Stationär',
				//ivwnutzung
				'16': encodeURIComponent(navigator.userAgent), //useragent
			};

			for (const i in customSiteVars) {
				if (customSiteVars[i] === undefined || customSiteVars[i] === '') {
					delete customSiteVars[i];
				}
			}

			tag.customVars.set({
				site: customSiteVars,
			});
			tag.dispatch();
			sendOfflineTracking(tag);
		}
	}
}
