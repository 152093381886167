import corejs from 'core-js';
import './libs/ati-smarttag';
import React from 'react';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import PictureCreditService from './services/pictureCredits/PictureCreditService';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
corejs; // prevent CRA to remove core-js

console.info(
	'👤 ARD.de',
	`🔢 Version: ${VERSION}`,
	`📦 Commit: ${COMMIT_HASH}`,
	`🕙 BuildDate: ${BUILD_DATE && new Date(BUILD_DATE).toLocaleString()}`,
);

const rootContainer = document.getElementById('root') as Element;
const root = createRoot(rootContainer);
root.render(
	<BrowserRouter>
		<PictureCreditService>
			<App />
		</PictureCreditService>
	</BrowserRouter>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
