import env from '../../configs/env';
import { AnyObject } from '../../types';
export function createAtiTag() {
	if ((window as AnyObject).ATInternet) {
		return new (window as AnyObject).ATInternet.Tracker.Tag({
			secure: true,
			site: env.ATI_ENVIRONMENT_ID,
			Offline: {
				storageMode: 'required',
			},
		});
	}

	return null;
}
