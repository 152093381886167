import React, { useCallback, useEffect } from 'react';
import { Banner, Column, Row } from '@ard-online/component-styleguide';
import { Root } from './styles';
import { usePictureCredits } from '../../../services/pictureCredits/PictureCreditService';
import { sendTeaserClick, sendTeaserImpression } from '../../../services/tracking';
import { PAGE_TITLES } from '../../../configs/types';
import { Widget } from '../../../types';
import { BUTTON_TYPES, Props as ButtonProps } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { cx } from '@linaria/core';

function BannerWidget({ id, headline, subTitle, href, image, widgetIndex }: Widget) {
	/** Variables */
	const { addPictureCredit } = usePictureCredits();

	const onTeaserClickMemo = useCallback(() => {
		sendTeaserClick(PAGE_TITLES.HOME, {
			widgetTitle: headline,
			widgetIndex,
			widgetId: id,
			teaserTitle: headline,
			teaserIndex: 0,
			teaserHref: href,
		});
	}, [headline, widgetIndex, href]);

	const onTeaserVisibleMemo = useCallback(() => {
		sendTeaserImpression(PAGE_TITLES.HOME, {
			widgetTitle: headline,
			widgetIndex,
			widgetId: id,
			teaserTitle: headline,
			teaserIndex: 0,
			teaserHref: href,
		});
	}, [headline, widgetIndex]);

	const button: ButtonProps & { rel?: string } = {
		onClick: onTeaserClickMemo,
		type: BUTTON_TYPES.PRIMARY,
		text: headline,
		href,
		target: '_blank',
		rel: 'noopener noreferrer',
	};

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		addPictureCredit({
			title: headline,
			source: image.imageSource,
			widgetIndex: widgetIndex,
		});
	}, [widgetIndex]);

	/** JSX */
	return (
		<section className={cx(Root.base)} id={`${id}Root`}>
			<Row justifyContent="center">
				<Column p={0}>
					<Banner
						image={{
							src: image.src,
							alt: image.alt,
							title: image.title,
							ratio: '16x7',
						}}
						description={subTitle}
						title={headline}
						button={button}
						onVisible={onTeaserVisibleMemo}
					/>
				</Column>
			</Row>
		</section>
	);
}

export default BannerWidget;
