import type { AnyFunction, AnyObject } from '../../types';
import { Props } from '@ard-online/component-styleguide/dist/components/Teaser/Teaser';
import { BreakingNewsItem } from './mapXMLBreakingNews';
import { getImagesWithAspectRatio } from '../image';
const MAX_NUMBER_OF_ITEMS = 20;
export default function mapXMLItemsWithTeasers(onTeaserClick: AnyFunction, onTeaserVisible: AnyFunction) {
	return (rawJsonData: AnyObject) => mapXMLItems(rawJsonData, onTeaserClick, onTeaserVisible);
}

function mapXMLItems(jsonData: AnyObject, onTeaserClick: AnyFunction, onTeaserVisible: AnyFunction, label?: string) {
	const items = jsonData && jsonData?.rss ? jsonData.rss.channel.item : jsonData.RDF.item;

	const mappedItems: Array<Props | BreakingNewsItem> = [];
	if (!items) {
		return mappedItems;
	}

	const maxItemsReached = () => mappedItems.length >= MAX_NUMBER_OF_ITEMS;

	for (let index = 0; index < items.length && !maxItemsReached(); index++) {
		const item = items[index];

		if (item.image) {
			const trackingInfo = {
				teaserTitle: item.title,
				teaserIndex: index,
				teaserHref: item.link,
				teaserType: item.type === 'Eilmeldung' ? 'eilmeldung' : 'text',
			};

			const onClick = () => onTeaserClick(trackingInfo);

			const onVisible = () => onTeaserVisible(trackingInfo);
			if (item.type === 'Eilmeldung') {
				mappedItems.push({
					id: item.link,
					type: 'eilmeldung',
					href: item.link,
					textPosition: 'onImage',
					textAlignment: 'center',
					label: label,
					overline: item.topline,
					title: item.title,
					teasertext: item.description,
					date: item.date,
				});
			} else {
				mappedItems.push({
					id: item.link,
					type: 'text',
					href: item.link,
					textPosition: 'onImage',
					textAlignment: 'center',
					label: label,
					overline: item.topline,
					title: item.title,
					onVisible: onVisible,
					onClick: onClick,
					image: getImagesWithAspectRatio(item.image),
				});
			}
		}
	}

	return mappedItems;
}
