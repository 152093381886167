import type { AnyFunction, AnyObject } from '../../types';
import { Props } from '@ard-online/component-styleguide/dist/components/Teaser/Teaser';
import { ImageData } from '../image/getImagesWithAspectRatio';

type Item = {
	id: string;
	title: string;
	duration: number;
	_links: {
		'mt:sharing': {
			href: string;
		};
		'mt:image': {
			name: string;
			href: string;
			title: string;
		};
	};
	_embedded: {
		'mt:programSet': {
			title: string;
		};
	};
};

type Ratio = '1x1' | '16x7' | '16x9';

function getSrcSetFromUrl(
	url: string,
	ratio: Ratio,
	sizes: Array<Array<number>> = [
		[320, 320],
		[480, 480],
		[600, 600],
		[768, 768],
		[960, 960],
		[1280, 1280],
		[1280, 1440],
		[1600, 1600],
		[1920, 1920],
	],
): string {
	let result = '';
	sizes.forEach((size) => {
		result += `${url.replace('{width}', size[0].toString()).replace('{ratio}', ratio)} ${size[1]}w,`;
	});

	return result.slice(0, -1);
}

function getSrc(url: string, size: number, ratio: Ratio): string {
	return url.replace('{width}', size.toString()).replace('{ratio}', ratio);
}

export default function mapJSONItemsATWithTeasers(onTeaserClick: AnyFunction, onTeaserVisible: AnyFunction) {
	return (rawJsonData: AnyObject) => mapJSONItemsAT(rawJsonData, onTeaserClick, onTeaserVisible);
}

function buildAspectRatios(imgItem: AnyObject): Partial<ImageData> {
	return {
		aspect1x1: getImageData(imgItem, '1x1'),
		aspect16x7: getImageData(imgItem, '16x7'),
		aspect16x9: getImageData(imgItem, '16x9'),
	};
}

function getImageData(imgItem: AnyObject, ratio: Ratio) {
	return {
		alt: `${imgItem?._links?.['mt:image']?.name} | ${imgItem?._links?.['mt:image']?.title}`,
		title: imgItem?._links?.['mt:image']?.name,
		src: getSrc(imgItem?._links?.['mt:image']?.href, 960, ratio),
		srcset: getSrcSetFromUrl(imgItem?._links?.['mt:image']?.href, ratio),
		source: imgItem?._links?.['mt:image']?.title,
	};
}

function mapJSONItemsAT(rawJsonData: AnyObject, onTeaserClick: AnyFunction, onTeaserVisible: AnyFunction) {
	const items = rawJsonData._embedded['mt:stageItems']._embedded['mt:items'];
	const mappedItems: Array<Partial<Props>> = [];

	if (items) {
		items
			.filter((item: Item) => !!item?._links?.['mt:sharing']?.href)
			.forEach((item: Item, index: number) => {
				const trackingInfo = {
					teaserTitle: item?.title,
					teaserIndex: index,
					teaserHref: item?._links?.['mt:sharing']?.href,
					teaserType: 'media',
				};

				const onClick = () => onTeaserClick(trackingInfo);

				const onVisible = () => onTeaserVisible(trackingInfo);

				mappedItems.push({
					id: item?.id,
					type: 'media',
					textPosition: 'onImage',
					textAlignment: 'center',
					duration: item?.duration,
					label: 'Audiothek',
					title: item?.title,
					href: item?._links?.['mt:sharing']?.href,
					subline: item?._embedded?.['mt:programSet']?.title,
					onVisible: onVisible,
					onClick: onClick,
					image: buildAspectRatios(item),
				});
			});
	}

	return mappedItems;
}
