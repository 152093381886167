import type { AnyObject } from '../../types';

type AspectRatios = 'aspect1x1' | 'aspect16x7' | 'aspect16x9';

interface ImageRatioData {
	srcset: string;
	src: string;
	alt: string;
	title: string;
	source: string;
}

export type ImageData = {
	aspect1x1?: ImageRatioData;
	aspect16x7?: ImageRatioData;
	aspect16x9?: ImageRatioData;
};

const aspectRatioMap: Record<number, AspectRatios> = {
	1.0: 'aspect1x1',
	2.3: 'aspect16x7',
	1.8: 'aspect16x9',
};

function createImageData(img: AnyObject) {
	return {
		srcset: `${img.data} ${img.width}w`,
		src: img.data,
		alt: img.alt + ' | ' + img.source,
		title: '',
		source: img.source,
	};
}

export default function getImagesWithAspectRatio(
	images: Array<AnyObject>,
): Partial<Record<AspectRatios, ImageRatioData>> {
	const imageData: Partial<ImageData> = {};

	images.forEach((img) => {
		if (img.width >= 320) {
			const ratio = Math.round((img.width * 10) / img.height) / 10;
			const aspectRatio: AspectRatios = aspectRatioMap[ratio] || 'aspect16x9'; //aspect ratio fallback e.g. 20:9

			if (!(aspectRatio in imageData)) {
				// first image with aspect ratio
				imageData[aspectRatio] = createImageData(img);
			} else {
				// otherwise update srcset
				(imageData[aspectRatio] as ImageRatioData).srcset += `,${img.data} ${img.width}w`;
			}
		}
	});

	return imageData;
}
