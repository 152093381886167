import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { FontStyles, FontSizes, ardTheme, Normalize } from '@ard-online/component-styleguide';
import { GlobalStyle, Root, ContentWrapper } from './styles';
import ScrollService from '../../services/scroll/ScrollService';
import FooterWidget from '../widgets/footer/FooterWidget';
import StartPage from '../pages/start/StartPage';
import SettingsPage from '../pages/settings/SettingsPage';
import 'swiper/css/bundle';
import { cx } from '@linaria/core';

function App() {
	return (
		<div className={cx(Root.base, ardTheme.base, FontSizes, FontStyles, GlobalStyle.base, Normalize)}>
			<ScrollService />
			<div className={cx(ContentWrapper.base)}>
				<Routes>
					<Route index element={<StartPage />} />
					<Route path="einstellungen" element={<SettingsPage />} />
				</Routes>
			</div>

			<FooterWidget />
		</div>
	);
}

export default App;
