import { css } from '@linaria/core';
import { Root as BannerRoot } from '@ard-online/component-styleguide/dist/components/Banner/styles';
import { RowRoot, Typo } from '@ard-online/component-styleguide';
import { Text } from '../../app/styles';

export const Root = {
	base: css`
		margin-bottom: 50px;

		h2 {
			color: rgb(var(--global-text-secondary)) !important;
		}

		.${Text.base} {
			a {
				display: inline-block;
				width: 100%;
				margin-bottom: -6px;

				.${Typo.heading02} {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
				}
			}
		}

		.${RowRoot.base} {
			margin-top: 0;
		}

		.${BannerRoot.base} {
			margin: 0 auto;
			background: transparent;
			padding: 10px;
		}
	`,
};
