import { css } from '@linaria/core';
import { Media, RowRoot, ColumnRoot, Typo } from '@ard-online/component-styleguide';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { cssEaseInOut } from '@ard-online/component-styleguide';

export const Root = {
	base: css`
		padding: 15px 0;
		color: rgb(var(--global-surface));
		background-color: rgb(var(--global-text-secondary));
		font-family: var(--fontFamily);

		.${ButtonRoot.base} {
			span {
				color: rgb(var(--global-surface));
			}

			&:hover {
				background: transparent !important;
			}
		}

		img {
			float: right;
		}

		.${RowRoot.base}:nth-child(2n) .${ColumnRoot.base} {
			padding-top: 0;
			padding-bottom: 0;
		}

		${Media('max', 'l')} {
			.${RowRoot.base} .${ColumnRoot.base}:nth-child(4) {
				margin-top: 30px;
			}
		}

		${Media('max', 'xs')} {
			.${RowRoot.base} .${ColumnRoot.base}:nth-child(3),
			.${RowRoot.base} .${ColumnRoot.base}:nth-child(4) {
				margin-top: 30px;
			}
		}

		.${Typo.heading02} {
			padding-left: 15px;
			font-size: 1.4rem;
			color: rgb(var(--global-surface)) !important;
		}

		.${ButtonRoot.base} {
			span {
				&:before {
					background: #fff !important;
				}
			}
		}
	`,
};
export const List = {
	base: css`
		height: 100%;
		margin: 0;
		padding: 0;
	`,
};
export const MetaList = {
	base: css`
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	`,
};
export const Listitem = {
	base: css`
		list-style-type: none;
		clear: both;
		margin: 0;

		.${ButtonRoot.base} {
			color: rgb(var(--global-text-secondary));

			${Media('max', 'l')} {
				font-size: 0.9rem;
			}

			&[data-active='true'] {
				border-bottom: 2px solid #fff;
				border-radius: 0;
			}

			&:hover,
			&:active,
			&:focus-within,
			&:focus {
				background: transparent !important;
				color: #fff !important;
				fill: #fff !important;
			}
		}
	`,
};
export const FooterLogo = {
	base: css`
		height: 48px;
	`,
};
export const Channels = {
	base: css`
		list-style-type: none;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 auto;
		padding: 10px 0;
	`,
};
export const ChannelLogo = {
	base: css`
		list-style-type: none;

		a {
			transition: 0.15s ${cssEaseInOut};

			svg {
				width: auto;
				height: 38px;
				display: block;
				padding: 5px 10px;
			}

			&:hover {
				transform: scale(1.12);
			}
		}
	`,
};
export const PictureCreditList = {
	base: css`
		margin: 50px 0 20px;
		padding: 0;
		width: 100%;
		list-style-type: none;
		position: relative;

		&:after {
			content: 'Bildnachweise';
			text-transform: uppercase;
			position: absolute;
			top: -30px;
			padding: 0 15px;
			font-size: 1rem;
			opacity: 0.6;
		}
	`,
};
export const PictureCreditsItem = {
	base: css`
		margin: 0;
		padding: 10px 15px;
		width: 100%;
		box-sizing: border-box;
	`,
};
