export enum DEVICE_TYPES {
	PHONE = 'phone',
	TABLET = 'tablet',
	RESPONSIVE = 'responsive',
}

export enum CONNECTION_TYPES {
	ONLINE = 'online',
	OFFLINE = 'offline',
}

export enum TEASER_TYPES {
	MEDIA = 'media',
	LIVE = 'live',
	SHOW = 'show',
	COLLECTION = 'collection',
}

export enum WIDGET_TYPES {
	TAGESSCHAU = 'tagesschau',
	MEDIATHEK = 'mediathek',
	SPORTSCHAU = 'sportschau',
	AUDIOTHEK = 'audiothek',
	KIKA = 'kika',
	TOPICS = 'topics',
	BREAKING_NEWS = 'eilmeldung',
}
export enum PAGE_TITLES {
	HOME = 'Startseite',
	SETTINGS = 'Einstellungen',
}
