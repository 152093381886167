import { TEASER_TYPES, WIDGET_TYPES } from '../../configs/types';
export function getTrackingVariant(widgetId: string, type?: TEASER_TYPES, teaserHref?: string) {
	switch (widgetId) {
		case WIDGET_TYPES.TAGESSCHAU:
			return 'Seite';

		case WIDGET_TYPES.MEDIATHEK:
			return mapTeaserTypeToMediathekTrackingVariant(type);

		case WIDGET_TYPES.SPORTSCHAU:
			return 'Seite';

		case WIDGET_TYPES.AUDIOTHEK:
			return 'Audio';

		case WIDGET_TYPES.KIKA:
			return 'Seite';

		case WIDGET_TYPES.TOPICS:
			return getTrackingVariantFromHostname(teaserHref);

		case WIDGET_TYPES.BREAKING_NEWS:
			return 'Seite';

		default:
			return '';
	}
}
const teaserTypeVariantMap = {
	[TEASER_TYPES.LIVE]: 'Livestream',
	[TEASER_TYPES.SHOW]: 'Sendung',
	[TEASER_TYPES.COLLECTION]: 'Sammlung',
	[TEASER_TYPES.MEDIA]: 'Video',
};

function mapTeaserTypeToMediathekTrackingVariant(type?: TEASER_TYPES): string {
	return (type && teaserTypeVariantMap[type]) || 'Video';
}

function getTrackingVariantFromHostname(teaserHref?: string) {
	if (!teaserHref) {
		return '';
	}

	const url = new URL(teaserHref);

	function isHostnameContaining(hostPart: string) {
		return url.hostname.includes(hostPart);
	}

	switch (true) {
		case isHostnameContaining('audiothek'):
			return 'Audio';

		case isHostnameContaining('sportschau'):
			return 'Seite';

		case isHostnameContaining('tagesschau'):
			return 'Seite';

		case isHostnameContaining('mediathek'):
			return getTrackingVariantFromHref(teaserHref);

		default:
			return 'Seite';
	}
}

function getTrackingVariantFromHref(href: string) {
	function isHrefContaining(urlpathPart: string) {
		return href.includes(urlpathPart);
	}

	switch (true) {
		case isHrefContaining('/video/'):
			return 'Video';

		case isHrefContaining('/sammlung/'):
			return 'Sammlung';

		case isHrefContaining('/live/'):
			return 'Livestream';

		case isHrefContaining('/sendung/'):
			return 'Sendung';

		default:
			return 'Seite';
	}
}
