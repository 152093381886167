import settingsService from '../settings/SettingsService';
import { createAtiTag } from './createAtiTag';
import { encodeForTracking, sendOfflineTracking } from './misc';
import { PRODUCT_NAME } from '../../configs/strings';
import { getTrackingVariant } from './trackingVariant';
import { isMobileDevice } from './deviceType';
import { getViewSize } from '../viewSize';
import type { TeaserTrackingInformationProps } from './TeaserTrackingInformationProps';
export default function sendAtiTag(
	pageTitle: string,
	teaserTrackingInformation: TeaserTrackingInformationProps,
	trackingField: string,
) {
	if (settingsService.trackingEnabled) {
		const tag = createAtiTag();

		if (tag) {
			tag.publisher.send({
				[trackingField]: createTeaserTrackingObject(pageTitle, teaserTrackingInformation),
			});
			sendOfflineTracking(tag);
		}
	}
}

function createTeaserTrackingObject(pageTitle: string, teaserTrackingInformation: TeaserTrackingInformationProps) {
	const { widgetTitle, widgetIndex, widgetId, teaserTitle, teaserIndex, teaserHref, teaserType } =
		teaserTrackingInformation;
	return {
		campaignId: `[${encodeForTracking(`${PRODUCT_NAME}/${pageTitle}`)}]`,
		creation: `[${encodeForTracking(teaserTitle)}]`,
		variant: `[${getTrackingVariant(widgetId, teaserType, teaserHref)}]`,
		format: `[${isMobileDevice() ? 'Mobil' : 'Stationär'}]`,
		generalPlacement: `[${encodeForTracking(widgetTitle)} | Pos. ${widgetIndex + 1}]`,
		detailedPlacement: `[${teaserHref}]`,
		advertiserId: `[ARD.de | Pos. ${teaserIndex + 1} | ${getViewSize()}]`,
		url: `[${teaserHref}]`,
	};
}
