import React, { forwardRef, Ref } from 'react';
import { PictureCreditList, PictureCreditsItem } from './styles';
import { Column } from '@ard-online/component-styleguide';
import { usePictureCredits } from '../../../services/pictureCredits/PictureCreditService';
import { PictureCreditProp } from '../../../services/pictureCredits/types';
import { cx } from '@linaria/core';

function sortPictureCreditsByWidgetIndex(pictureCredits: Array<PictureCreditProp>) {
	return pictureCredits.sort((firstCredit, secondCredit) => firstCredit.widgetIndex - secondCredit.widgetIndex);
}

type Props = unknown;

function PictureCredits(props: Props, ref: unknown) {
	/** Variables */
	const { pictureCredits } = usePictureCredits();

	/** JSX */
	if (pictureCredits.length) {
		const pictureCreditsInOrder = sortPictureCreditsByWidgetIndex(pictureCredits);
		return (
			<div ref={ref as Ref<HTMLDivElement>}>
				<Column all={12} p={0}>
					<ul className={cx(PictureCreditList.base)}>
						{pictureCreditsInOrder.map((pictureCredit, index) => (
							<li className={cx(PictureCreditsItem.base)} key={'pictureCreditId_' + index}>
								{pictureCredit.title} &middot; <strong>Bildquelle:</strong> {pictureCredit.source}
							</li>
						))}
					</ul>
				</Column>
			</div>
		);
	} else {
		return null;
	}
}

export default forwardRef<Props>(PictureCredits);
