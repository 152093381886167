import { DEVICE_TYPES } from '../../configs/types';

export function deviceType(): DEVICE_TYPES {
	if (/Mobi|Android|iPad|iPhone|Windows Phone|BlackBerry|Tablet|Kindle|Silk/.test(navigator.userAgent)) {
		if (/mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent)) {
			return DEVICE_TYPES.PHONE;
		} else {
			return DEVICE_TYPES.TABLET;
		}
	}

	return DEVICE_TYPES.RESPONSIVE;
}

export function isMobileDevice() {
	return deviceType() === DEVICE_TYPES.PHONE || deviceType() === DEVICE_TYPES.TABLET;
}
export function isPhoneDevice() {
	return deviceType() === DEVICE_TYPES.PHONE;
}
