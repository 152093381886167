import { css } from '@linaria/core';
import { Media, ColumnRoot, Typo } from '@ard-online/component-styleguide';

export const Root = {
	base: css`
		margin-bottom: 60px;
		overflow: hidden;
		font-family: var(--fontFamily);

		${Media('max', 'xs')} {
			margin-bottom: 30px;
		}

		.${Typo.heading02}, .${Typo.body02} {
			color: rgb(var(--global-text)) !important;
			padding: 0 10px 0 10px;
			white-space: inherit;
		}

		.${Typo.heading02} {
			text-overflow: inherit;
		}

		.${Typo.body02} {
			padding: 0 10px 10px;
		}

		.${ColumnRoot.base} {
			color: rgb(var(--global-text));
			background-color: #b3053a;
		}
	`,
};
export const Label = {
	base: css`
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		text-transform: uppercase;
		padding-bottom: 5px;
		color: rgb(var(--global-text));
		fill: rgb(var(--global-text));

		svg {
			width: 38px;

			${Media('max', 'xs')} {
				display: none;
			}
		}

		${Media('max', 'xs')} {
			padding-left: 10px;
			font-size: 0.9rem;
		}
	`,
};
export const Datetime = {
	base: css`
		padding: 0 0 0 20px;
		margin: 0;
		text-transform: uppercase;
		letter-spacing: normal;
		color: rgb(var(--global-text));

		${Media('max', 'xs')} {
			padding-left: 10px;
		}
	`,
};
