/* eslint-disable */
// @ts-nocheck
(function () {
  var dfltPluginCfg = {
    sourceFile: "download",
    info: true
  };
  var dfltGlobalCfg = {
    site: 511894,
    log: "image",
    logSSL: "image",
    domain: "ard.de",
    secure: true,
    pixelPath: "/ard.gif",
    collectDomain: "image.ard.de",
    collectDomainSSL: "image.ard.de",
    disableCookie: false,
    cookieSecure: false,
    cookieDomain: "ardmediathek.de",
    preview: false,
    plgs: ["Campaigns", "Clicks", "ContextVariables", "IdentifiedVisitor", "InternalSearch", "Offline", "OnSiteAds", "Page", "RichMedia"],
    lazyLoadingPath: "",
    documentLevel: "document",
    redirect: false,
    activateCallbacks: true,
    medium: "",
    ignoreEmptyChapterValue: true,
    base64Storage: false
  };

  (function (a) {
    a.ATInternet = a.ATInternet || {};
    a.ATInternet.Tracker = a.ATInternet.Tracker || {};
    a.ATInternet.Tracker.Plugins = a.ATInternet.Tracker.Plugins || {};
  })(window);

  var Utils = function () {
    function a(b) {
      var e = typeof b;
      if ("object" !== e || null === b) return "string" === e && (b = '"' + b + '"'), String(b);
      var c,
          h,
          n = [],
          f = b && b.constructor === Array;

      for (c in b) b.hasOwnProperty(c) && (h = b[c], e = typeof h, "function" !== e && "undefined" !== e && ("string" === e ? h = '"' + h.replace(/[^\\]"/g, '\\"') + '"' : "object" === e && null !== h && (h = a(h)), n.push((f ? "" : '"' + c + '":') + String(h))));

      return (f ? "[" : "{") + String(n) + (f ? "]" : "}");
    }

    function d(a) {
      return null === a ? "" : (a + "").replace(r, "");
    }

    function g(a) {
      var e,
          c = null;
      return (a = d(a + "")) && !d(a.replace(m, function (a, n, f, p) {
        e && n && (c = 0);
        if (0 === c) return a;
        e = f || n;
        c += !p - !f;
        return "";
      })) ? Function("return " + a)() : null;
    }

    var b = this,
        m = /(,)|(\[|{)|(}|])|"(?:[^"\\\r\n]|\\["\\\/bfnrt]|\\u[\da-fA-F]{4})*"\s*:?|true|false|null|-?(?!0\d)\d+(?:\.\d+|)(?:[eE][+-]?\d+|)/g,
        r = RegExp("^[\\x20\\t\\r\\n\\f]+|((?:^|[^\\\\])(?:\\\\.)*)[\\x20\\t\\r\\n\\f]+$", "g");

    b.loadScript = function (a, e) {
      var c;

      e = e || function () {};

      c = document.createElement("script");
      c.type = "text/javascript";
      c.src = a.url;
      c.async = !1;
      c.defer = !1;

      c.onload = c.onreadystatechange = function (a) {
        a = a || window.event;
        if ("load" === a.type || /loaded|complete/.test(c.readyState) && (!document.documentMode || 9 > document.documentMode)) c.onload = c.onreadystatechange = c.onerror = null, e(null, a);
      };

      c.onerror = function (a, f, p) {
        c.onload = c.onreadystatechange = c.onerror = null;
        e({
          msg: "script not loaded",
          event: a
        });
      };

      var h = document.head || document.getElementsByTagName("head")[0];
      h.insertBefore(c, h.lastChild);
    };

    b.cloneSimpleObject = function e(a, h) {
      if ("object" !== typeof a || null === a || a instanceof Date) return a;
      var n = new a.constructor(),
          f;

      for (f in a) a.hasOwnProperty(f) && (void 0 === f || h && void 0 === a[f] || (n[f] = e(a[f])));

      return n;
    };

    b.jsonSerialize = function (e) {
      try {
        return "undefined" !== typeof JSON && JSON.stringify ? JSON.stringify(e) : a(e);
      } catch (c) {
        return null;
      }
    };

    b.jsonParse = function (a) {
      try {
        return "undefined" !== typeof JSON && JSON.parse ? JSON.parse(a + "") : g(a);
      } catch (c) {
        return null;
      }
    };

    b.arrayIndexOf = function (a, c) {
      return Array.indexOf ? a.indexOf(c) : function (a) {
        if (null == this) throw new TypeError();
        var c = Object(this),
            f = c.length >>> 0;
        if (0 === f) return -1;
        var p = 0;
        1 < arguments.length && (p = Number(arguments[1]), p != p ? p = 0 : 0 != p && Infinity != p && -Infinity != p && (p = (0 < p || -1) * Math.floor(Math.abs(p))));
        if (p >= f) return -1;

        for (p = 0 <= p ? p : Math.max(f - Math.abs(p), 0); p < f; p++) if (p in c && c[p] === a) return p;

        return -1;
      }.apply(a, [c]);
    };

    b.uuid = function () {
      return {
        v4: function () {
          return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (a) {
            var c = 16 * Math.random() | 0;
            return ("x" === a ? c : c & 3 | 8).toString(16);
          });
        },
        num: function (a) {
          var c = new Date(),
              h = function (a) {
            a -= 100 * Math.floor(a / 100);
            return 10 > a ? "0" + a : String(a);
          };

          return h(c.getHours()) + "" + h(c.getMinutes()) + "" + h(c.getSeconds()) + "" + function (a) {
            return Math.floor((9 * Math.random() + 1) * Math.pow(10, a - 1));
          }(a - 6);
        }
      };
    };

    b.getObjectKeys = function (a) {
      var c = [],
          h;

      for (h in a) a.hasOwnProperty(h) && c.push(h);

      return c;
    };

    b.completeFstLevelObj = function (a, c, h) {
      if (a) {
        if (c) for (var n in c) !c.hasOwnProperty(n) || a[n] && !h || (a[n] = c[n]);
      } else a = c;

      return a;
    };

    b.isPreview = function () {
      return window.navigator && "preview" === window.navigator.loadPurpose;
    };

    b.isPrerender = function (a) {
      var c,
          h = !1,
          n = ["webkit", "ms"];
      if ("prerender" === document.visibilityState) c = "visibilitychange";else for (var f = 0; f < n.length; f++) "prerender" === document[n[f] + "VisibilityState"] && (c = n[f] + "visibilitychange");

      if ("undefined" !== typeof c) {
        var p = function (f) {
          a(f);
          b.removeEvtListener(document, c, p);
        };

        b.addEvtListener(document, c, p);
        h = !0;
      }

      return h;
    };

    var l = b.addEvtListener = function (a, c, h) {
      a.addEventListener ? a.addEventListener(c, h, !1) : a.attachEvent && a.attachEvent("on" + c, h);
    },
        k = b.removeEvtListener = function (a, c, h) {
      a.removeEventListener ? a.removeEventListener(c, h, !1) : a.detachEvent && a.detachEvent("on" + c, h);
    };

    b.hashcode = function (a) {
      var c = 0;
      if (0 === a.length) return c;

      for (var h = 0; h < a.length; h++) var n = a.charCodeAt(h), c = (c << 5) - c + n, c = c | 0;

      return c;
    };

    b.setLocation = function (a) {
      var c = a.location;
      a = window[a.target] || window;
      c && (a.location.href = c);
    };

    b.dispatchCallbackEvent = function (a) {
      var c;
      "function" === typeof window.Event ? c = new Event("ATCallbackEvent") : (c = document.createEvent("Event"), c.initEvent && c.initEvent("ATCallbackEvent", !0, !0));
      c && (c.name = a, document.dispatchEvent(c));
    };

    b.addCallbackEvent = function (a) {
      l(document, "ATCallbackEvent", a);
    };

    (function () {
      function a(c, e) {
        e = e || {
          bubbles: !1,
          cancelable: !1,
          detail: void 0
        };
        var n = document.createEvent("CustomEvent");
        n.initCustomEvent(c, e.bubbles, e.cancelable, e.detail);
        return n;
      }

      if ("function" === typeof window.CustomEvent) return window.ATCustomEvent = window.CustomEvent, !1;
      a.prototype = window.Event.prototype;
      window.ATCustomEvent = a;
    })();

    b.addOptOutEvent = function (a, c) {
      b.ATOptOutEvent = new ATCustomEvent("ATOptOutEvent", {
        detail: {
          name: "clientsideuserid",
          id: c
        }
      });
      l(document, "ATOptOutEvent", a);
    };

    b.removeOptOutEvent = function (a) {
      k(document, "ATOptOutEvent", a);
    };

    b.dispatchOptOutEvent = function (a) {
      b.optedOut = a;
      b.ATOptOutEvent = b.ATOptOutEvent || new ATCustomEvent("ATOptOutEvent", {
        detail: {
          name: "clientsideuserid",
          id: -1
        }
      });
      document.dispatchEvent(b.ATOptOutEvent);
    };

    b.userOptedOut = function () {
      b.dispatchOptOutEvent(!0);
    };

    b.userOptedIn = function () {
      b.dispatchOptOutEvent(!1);
    };

    b.isOptedOut = function () {
      return !!b.optedOut;
    };

    b.optedOut = null;
    b.ATOptOutEvent = null;
  };

  window.ATInternet.Utils = new Utils();

  var BuildManager = function (a) {
    var d = this,
        g = function (a, e, c) {
      a = "&" + a + "=";
      return {
        param: a,
        paramSize: a.length,
        str: e,
        strSize: e.length,
        truncate: c
      };
    },
        b = function (b, e) {
      var c = "",
          h = 0,
          n = !0,
          f;

      for (f in b) if (b.hasOwnProperty(f)) {
        var p = b[f];

        if (p) {
          var n = !1,
              k = e - h;
          if (p.strSize + p.paramSize < k) c += p.param + p.str, h += p.strSize + p.paramSize, b[f] = void 0, n = !0;else {
            p.truncate ? (c += p.param + p.str.substr(0, k), b[f].str = p.str.substr(k, p.strSize - 1), b[f].strSize = b[f].str.length) : p.strSize + p.paramSize > e && (a.emit("Tracker:Hit:Build:Error", {
              lvl: "ERROR",
              msg: 'Too long parameter "' + p.param + '"',
              details: {
                value: p.str
              }
            }), b[f].str = b[f].str.substr(0, e - p.paramSize - 1), b[f].strSize = b[f].str.length);
            break;
          }
        } else n = !0;
      }

      return [c, n ? null : b];
    },
        m = ["ati", "atc", "pdtl", "stc", "dz"],
        r = function (k, e, c) {
      var h = function (a) {
        if (a === {}) return "";
        var c = [],
            f;
        f = {};
        var e = 0,
            n = !1,
            h = void 0,
            k,
            d,
            q;

        for (q in a) if (a.hasOwnProperty(q)) {
          var l = a[q].value;
          "function" === typeof l && (l = l());
          l = l instanceof Array ? l.join(a[q].options.separator || ",") : "object" === typeof l ? window.ATInternet.Utils.jsonSerialize(l) : "undefined" === typeof l ? "undefined" : l.toString();
          a[q].options.encode && (l = encodeURIComponent(l));
          var r = g(q, l, -1 < window.ATInternet.Utils.arrayIndexOf(m, q)),
              e = e + (r.paramSize + r.strSize);
          if (a[q].options.last) 1490 < l.length && l.substr(0, 1490), k = q, d = r;else if (f[q] = r, 1500 < f[q].paramSize + f[q].strSize && !f[q].truncate) {
            n = !0;
            h = q;
            break;
          }
        }

        k && (f[k] = d);
        f = [f, e, n, h];
        a = f[0];
        if (f[2]) f = f[3], a = a[f], a.str = a.str.substr(0, 1450), a.strSize = 1450, e = {}, e.mherr = g("mherr", "1", !1), e[f] = a, c.push(b(e, 1500)[0]);else if (f = b(a, 1500), null === f[1]) c = f[0];else for (c.push(f[0]); null !== f[1];) f = b(a, 1500), c.push(f[0]);
        return c;
      },
          n = "";

      a.buffer.presentInFilters(e, "hitType") || (e = a.buffer.addInFilters(e, "hitType", ["page"]));
      e = a.buffer.addInFilters(e, "hitType", ["all"]);
      var f;

      if (k) {
        e = a.buffer.addInFilters(e, "permanent", !0);
        e = a.buffer.get(e, !0);

        for (f in k) k.hasOwnProperty(f) && (e[f] = {
          value: k[f],
          options: {}
        });

        n = h(e);
      } else for (f in e = a.buffer.get(e, !0), n = h(e), e) e.hasOwnProperty(f) && !e[f].options.permanent && a.buffer.del(f);

      c && c(n);
    },
        l = function (b) {
      var e = a.getConfig("secure"),
          c = "https:" === document.location.protocol,
          h = e || c,
          c = "",
          c = h ? a.getConfig("logSSL") : a.getConfig("log"),
          n = a.getConfig("domain"),
          c = c && n ? c + "." + n : h ? a.getConfig("collectDomainSSL") : a.getConfig("collectDomain"),
          h = a.getConfig("baseURL");
      (n = a.getConfig("pixelPath")) && "/" !== n.charAt(0) && (n = "/" + n);
      var f = a.getConfig("site");
      (h || c && n) && f ? b && b(null, (h ? h : (e ? "https://" : "//") + c + n) + ("?s=" + f)) : b && b({
        message: "Config error"
      });
    },
        k = function (a, e, c) {
      l(function (b, n) {
        b ? c && c(b) : r(a, e, function (a) {
          var p = [],
              e = ATInternet.Utils.uuid().num(13);
          if (a instanceof Array) {
            if (1 === a.length) p.push(n + "&mh=1-2-" + e + a[0]);else for (var b = 1; b <= a.length; b++) p.push(n + "&mh=" + b + "-" + a.length + "-" + e + a[b - 1]);
          } else p.push(n + a);
          c && c(null, p);
        });
      });
    };

    d.send = function (b, e, c) {
      k(b, e, function (e, b) {
        if (e) a.emit("Tracker:Hit:Build:Error", {
          lvl: "ERROR",
          msg: e.message,
          details: {
            hits: b
          }
        });else for (var f = 0; f < b.length; f++) d.sendUrl(b[f], c);
      });
    };

    d.sendUrl = function (b, e) {
      var c = function (c, f) {
        return function () {
          return function (p) {
            a.emit(c, {
              lvl: -1 === c.indexOf("Error") ? "INFO" : "ERROR",
              details: {
                hit: f,
                event: p
              }
            });
            e && e();
          };
        }();
      },
          k = new Image();

      k.onload = c("Tracker:Hit:Sent:Ok", b);
      k.onerror = c("Tracker:Hit:Sent:Error", b);
      k.src = b;
    };
  },
      TriggersManager = function () {
    function a(a, b, l) {
      for (var k = [], d = 0; d < a.length; d++) a[d].callback(b, l), a[d].singleUse || k.push(a[d]);

      return k;
    }

    function d(a, b, l, k) {
      var q = a.shift();
      if ("*" === q) return b["*"] = b["*"] || [], b["*"].push({
        callback: l,
        singleUse: k
      }), b["*"].length - 1;
      if (0 === a.length) return d([q, "*"], b, l, k);
      b["*"] = b["*"] || [];
      b[q] = b[q] || {};
      return d(a, b[q], l, k);
    }

    function g(b, d, l, k) {
      var q = d.shift();
      "*" !== q && (0 === d.length ? g(b, [q, "*"], l, k) : l[q] && (l[q]["*"] = a(l[q]["*"], b, k), g(b, d, l[q], k)));
    }

    var b = {};

    this.on = function (a, r, l) {
      l = l || !1;
      return d(a.split(":"), b, r, l);
    };

    this.emit = function (m, d) {
      b["*"] && (b["*"] = a(b["*"], m, d));
      g(m, m.split(":"), b, d);
    };
  },
      PluginsManager = function (a) {
    var d = {},
        g = {},
        b = 0,
        m = {},
        r = 0,
        l = this.unload = function (c) {
      d[c] ? (d[c] = void 0, a.emit("Tracker:Plugin:Unload:" + c + ":Ok", {
        lvl: "INFO"
      })) : a.emit("Tracker:Plugin:Unload:" + c + ":Error", {
        lvl: "ERROR",
        msg: "not a known plugin"
      });
      return a;
    },
        k = this.load = function (c, p) {
      "function" === typeof p ? "undefined" === typeof a.getConfig.plgAllowed || 0 === a.getConfig.plgAllowed.length || -1 < a.getConfig.plgAllowed.indexOf(c) ? (d[c] = new p(a), g[c] && d[c] && (g[c] = !1, b--, d[c + "_ll"] && l(c + "_ll"), 0 === b && a.emit("Tracker:Plugin:Lazyload:File:Complete", {
        lvl: "INFO",
        msg: "LazyLoading triggers are finished"
      })), a.emit("Tracker:Plugin:Load:" + c + ":Ok", {
        lvl: "INFO"
      })) : a.emit("Tracker:Plugin:Load:" + c + ":Error", {
        lvl: "ERROR",
        msg: "Plugin not allowed",
        details: {}
      }) : a.emit("Tracker:Plugin:Load:" + c + ":Error", {
        lvl: "ERROR",
        msg: "not a function",
        details: {
          obj: p
        }
      });
      return a;
    },
        q = this.isLazyloading = function (a) {
      return a ? !0 === g[a] : 0 !== b;
    },
        e = function (a) {
      return !d[a] && !q(a) && (d[a + "_ll"] ? !0 : !1);
    },
        c = function (c) {
      g[c] = !0;
      b++;
      ATInternet.Utils.loadScript({
        url: a.getConfig("lazyLoadingPath") + c + ".js"
      });
    },
        h = function (a) {
      return e(a) ? (c(a), !0) : !1;
    },
        n = function (a) {
      m[a] ? m[a]++ : m[a] = 1;
      r++;
    };

    this.isExecWaitingLazyloading = function () {
      return 0 !== r;
    };

    a.exec = this.exec = function (f, p, b, k) {
      var h = null,
          l = function (a, c, f, p) {
        c = c.split(".");
        d[a] && d[a][c[0]] && (h = 1 < c.length && d[a][c[0]][c[1]] ? d[a][c[0]][c[1]].apply(d[a], f) : d[a][c[0]].apply(d[a], f));
        p && p(h);
      },
          g = function (c, f, p, b) {
        n(c);
        a.onTrigger("Tracker:Plugin:Load:" + c + ":Ok", function () {
          l(c, f, p, function (f) {
            m[c]--;
            r--;
            0 === r && a.emit("Tracker:Plugin:Lazyload:Exec:Complete", {
              lvl: "INFO",
              msg: "All exec waiting for lazyloading are done"
            });
            b && b(f);
          });
        }, !0);
      };

      e(f) ? (g(f, p, b, k), c(f)) : q(f) ? g(f, p, b, k) : l(f, p, b, k);
    };

    this.waitForDependencies = function (c, p) {
      var b = function (a) {
        for (var c = {
          mcount: 0,
          plugins: {}
        }, f = 0; f < a.length; f++) d.hasOwnProperty(a[f]) || (c.mcount++, c.plugins[a[f]] = !0);

        return c;
      }(c);

      if (0 === b.mcount) a.emit("Tracker:Plugin:Dependencies:Loaded", {
        lvl: "INFO",
        details: {
          dependencies: c
        }
      }), p();else for (var e in b.plugins) b.plugins.hasOwnProperty(e) && (a.emit("Tracker:Plugin:Dependencies:Error", {
        lvl: "WARNING",
        msg: "Missing plugin " + e
      }), a.onTrigger("Tracker:Plugin:Load:" + e, function (a, c) {
        var f = a.split(":"),
            e = f[3];
        "Ok" === f[4] && (b.plugins[e] = !1, b.mcount--, 0 === b.mcount && p());
      }, !0), h(e));
    };

    this.init = function () {
      for (var a in ATInternet.Tracker.pluginProtos) ATInternet.Tracker.pluginProtos.hasOwnProperty(a) && k(a, ATInternet.Tracker.pluginProtos[a]);
    };
  },
      CallbacksManager = function (a) {
    var d = {},
        g = this.load = function (b, m) {
      "function" === typeof m ? (new m(a), a.emit("Tracker:Callback:Load:" + b + ":Ok", {
        lvl: "INFO",
        details: {
          obj: m
        }
      })) : a.emit("Tracker:Callback:Load:" + b + ":Error", {
        lvl: "ERROR",
        msg: "not a function",
        details: {
          obj: m
        }
      });
      return a;
    };

    this.init = function () {
      if (a.getConfig("activateCallbacks")) {
        var b = a.getConfig("callbacks");

        (function () {
          if ("undefined" !== typeof b && b.include instanceof Array) for (var a = 0; a < b.include.length; a++) ATInternet.Callbacks && ATInternet.Callbacks.hasOwnProperty(b.include[a]) && (d[b.include[a]] = {
            function: ATInternet.Callbacks[b.include[a]]
          }, ATInternet.Tracker.callbackProtos[b.include[a]] || (ATInternet.Tracker.callbackProtos[b.include[a]] = d[b.include[a]]));else for (a in ATInternet.Callbacks) ATInternet.Callbacks.hasOwnProperty(a) && (d[a] = {
            function: ATInternet.Callbacks[a]
          }, ATInternet.Tracker.callbackProtos[a] || (ATInternet.Tracker.callbackProtos[a] = d[a]));
          if ("undefined" !== typeof b && b.exclude instanceof Array) for (a = 0; a < b.exclude.length; a++) d[b.exclude[a]] && (d[b.exclude[a]] = void 0);

          for (var r in d) d.hasOwnProperty(r) && d[r] && g(r, d[r]["function"]);
        })();

        ATInternet.Utils.addCallbackEvent(function (a) {
          if (a.name) {
            var d = !0;
            "undefined" !== typeof b && (b.include instanceof Array && -1 === ATInternet.Utils.arrayIndexOf(b.include, a.name) && (d = !1), b.exclude instanceof Array && -1 !== ATInternet.Utils.arrayIndexOf(b.exclude, a.name) && (d = !1));

            if (ATInternet.Callbacks && ATInternet.Callbacks.hasOwnProperty(a.name)) {
              var l = {};
              l[a.name] = {
                function: ATInternet.Callbacks[a.name]
              };
              d && g(a.name, l[a.name]["function"]);
              ATInternet.Tracker.callbackProtos[a.name] || (ATInternet.Tracker.callbackProtos[a.name] = l[a.name]);
            }
          }
        });
      }
    };
  },
      BufferManager = function (a) {
    var d = {};

    this.set = function (a, b, l) {
      l = l || {};
      l.hitType = l.hitType || ["page"];
      d[a] = {
        value: b,
        options: l
      };
    };

    var g = function (a, b, d) {
      return (a = window.ATInternet.Utils.cloneSimpleObject(a[b])) && !d ? a.value : a;
    },
        b = function r(a, b) {
      if (!(a && b instanceof Array && a instanceof Array)) return [];
      if (0 === a.length) return b;
      var q = a[0],
          e,
          c = [],
          h = window.ATInternet.Utils.cloneSimpleObject(a);
      h.shift();

      for (var n = 0; n < b.length; n++) if ("object" !== typeof q[1]) d[b[n]] && d[b[n]].options[q[0]] === q[1] && c.push(b[n]);else {
        e = q[1].length;

        for (var f = 0; f < e; f++) if (d[b[n]] && d[b[n]].options[q[0]] instanceof Array && 0 <= window.ATInternet.Utils.arrayIndexOf(d[b[n]].options[q[0]], q[1][f])) {
          c.push(b[n]);
          break;
        }
      }

      return r(h, c);
    };

    this.get = function (a, l) {
      var k = {};
      if ("string" === typeof a) k = g(d, a, l);else for (var q = b(a, window.ATInternet.Utils.getObjectKeys(d)), e = 0; e < q.length; e++) k[q[e]] = g(d, q[e], l);
      return k;
    };

    this.presentInFilters = function l(a, b) {
      return a && 0 !== a.length ? a[0][0] === b ? !0 : l(a.slice(1), b) : !1;
    };

    this.addInFilters = function k(a, b, c, h) {
      if (!a || 0 === a.length) return h ? [] : [[b, c]];
      var n = a[0][0],
          f = a[0][1];
      n === b && (f instanceof Array && !(-1 < window.ATInternet.Utils.arrayIndexOf(f, c[0])) && f.push(c[0]), h = !0);
      return [[n, f]].concat(k(a.slice(1), b, c, h));
    };

    this.del = function (a) {
      d[a] = void 0;
    };

    this.clear = function () {
      d = {};
    };
  },
      Tag = function (a, d, g) {
    d = d || {};
    var b = this;
    b.version = "5.13.2";
    var m = window.ATInternet.Utils.cloneSimpleObject(d);
    b.triggers = new TriggersManager(b);
    b.emit = b.triggers.emit;
    b.onTrigger = b.triggers.on;
    var r = window.ATInternet.Utils.cloneSimpleObject(dfltGlobalCfg) || {},
        l;

    for (l in a) a.hasOwnProperty(l) && (r[l] = a[l]);

    b.getConfig = function (a) {
      return r[a];
    };

    b.setConfig = function (a, d, e) {
      void 0 !== r[a] && e || (b.emit("Tracker:Config:Set:" + a, {
        lvl: "INFO",
        details: {
          bef: r[a],
          aft: d
        }
      }), r[a] = d);
    };

    b.configPlugin = function (a, d, e) {
      r[a] = r[a] || {};

      for (var c in d) d.hasOwnProperty(c) && void 0 === r[a][c] && (r[a][c] = d[c]);

      e && (e(r[a]), b.onTrigger("Tracker:Config:Set:" + a, function (a, c) {
        e(c.details.aft);
      }));
      return r[a];
    };

    b.getContext = function (a) {
      return m[a];
    };

    b.setContext = function (a, d) {
      b.emit("Tracker:Context:Set:" + a, {
        lvl: "INFO",
        details: {
          bef: m[a],
          aft: d
        }
      });
      m[a] = d;
    };

    b.delContext = function (a, d) {
      b.emit("Tracker:Context:Deleted:" + a + ":" + d, {
        lvl: "INFO",
        details: {
          key1: a,
          key2: d
        }
      });
      if (a) m.hasOwnProperty(a) && (d ? m[a] && m[a].hasOwnProperty(d) && (m[a][d] = void 0) : m[a] = void 0);else if (d) for (var e in m) m.hasOwnProperty(e) && m[e] && m[e].hasOwnProperty(d) && (m[e][d] = void 0);
    };

    b.plugins = new PluginsManager(b);
    b.buffer = new BufferManager(b);
    b.setParam = b.buffer.set;

    b.getParams = function (a) {
      return b.buffer.get(a, !1);
    };

    b.getParam = b.buffer.get;
    b.delParam = b.buffer.del;
    b.builder = new BuildManager(b);
    b.sendHit = b.builder.send;
    b.sendUrl = b.builder.sendUrl;
    b.callbacks = new CallbacksManager(b);
    b.setParam("ts", function () {
      return new Date().getTime();
    }, {
      permanent: !0,
      hitType: ["all"]
    });
    b.getConfig("disableCookie") && b.setParam("idclient", "Consent-NO", {
      permanent: !0,
      hitType: ["all"]
    });
    b.getConfig("medium") && b.setParam("medium", b.getConfig("medium"), {
      permanent: !0,
      hitType: ["all"]
    });
    b.plugins.init();
    b.callbacks.init();
    ATInternet.Tracker.instances.push(b);
    b.emit("Tracker:Ready", {
      lvl: "INFO",
      msg: "Tracker initialized",
      details: {
        tracker: b,
        args: {
          config: a,
          context: d,
          callback: g
        }
      }
    });
    g && g(b);
  };

  ATInternet.Tracker.Tag = Tag;
  ATInternet.Tracker.instances = [];
  ATInternet.Tracker.pluginProtos = {};

  ATInternet.Tracker.addPlugin = function (a, d) {
    d = d || ATInternet.Tracker.Plugins[a];

    if (!ATInternet.Tracker.pluginProtos[a]) {
      ATInternet.Tracker.pluginProtos[a] = d;

      for (var g = 0; g < ATInternet.Tracker.instances.length; g++) ATInternet.Tracker.instances[g].plugins.load(a, d);
    }
  };

  ATInternet.Tracker.delPlugin = function (a) {
    if (ATInternet.Tracker.pluginProtos[a]) {
      ATInternet.Tracker.pluginProtos[a] = void 0;

      for (var d = 0; d < ATInternet.Tracker.instances.length; d++) ATInternet.Tracker.instances[d].plugins.unload(a);
    }
  };

  ATInternet.Tracker.callbackProtos = {};
}).call(window);
(function () {
  var dfltPluginCfg = {
    lifetime: 30,
    lastPersistence: true,
    listEventsForExec: [],
    domainAttribution: true,
    info: true
  };
  var dfltGlobalCfg = {
    visitLifetime: 30,
    redirectionLifetime: 30
  };

  window.ATInternet.Tracker.Plugins.Campaigns = function (a) {
    a.setConfig("visitLifetime", dfltGlobalCfg.visitLifetime, !0);
    a.setConfig("redirectionLifetime", dfltGlobalCfg.redirectionLifetime, !0);
    var d = {},
        g,
        b;
    a.configPlugin("Campaigns", dfltPluginCfg || {}, function (a) {
      d = a;
    });

    var m,
        r,
        l,
        k,
        q,
        e,
        c,
        h,
        n,
        f,
        p,
        w,
        x,
        u = function (c, b, f) {
      var p = null;
      a.plugins.exec(c, b, f, function (a) {
        p = a;
      });
      return p;
    },
        s = function (a, c) {
      return u("Cookies", a, c);
    },
        y = function (a, c) {
      return u("Utils", a, c);
    },
        z = function (a, c) {
      var f = s(b, [a]);
      if (null !== f) return "object" === typeof f && !(f instanceof Array);
      s(g, [a, {}, c]);
      return !0;
    },
        B = function (c, b) {
      var f = a.getContext("campaigns") || {};
      f[c] = b;
      a.setContext("campaigns", f);
    },
        A = function (u) {
      g = "set" + (d.domainAttribution ? "" : "Private");
      b = "get" + (d.domainAttribution ? "" : "Private");
      m = s(b, [["atredir", "gopc"]]);
      r = s(b, [["atredir", "gopc_err"]]);
      l = s(b, [["atredir", "camp"]]);
      s("del", [["atredir", "gopc"]]);
      s("del", [["atredir", "gopc_err"]]);
      s("del", [["atredir", "camp"]]);
      k = s(b, [["atsession", "histo_camp"]]);
      q = s(b, [["atreman", "camp"]]);
      e = s(b, [["atreman", "date"]]);
      var t = y("getLocation", []);
      c = y("getQueryStringValue", ["xtor", t]);
      h = y("getQueryStringValue", ["xtdt", t]);
      n = y("getQueryStringValue", ["xts", t]);
      f = a.getContext("forcedCampaign");
      p = !!a.getConfig("redirect");
      if (w = c && h && n ? !0 : !1) t = new Date().getTime() / 6e4, x = !p && n !== a.getConfig("site") || 0 > t - h || t - h >= a.getConfig("visitLifetime") ? !0 : !1;
      t = f || l || c;

      if (p && t && z("atredir", {
        path: "/",
        end: a.getConfig("redirectionLifetime")
      })) {
        s(g, [["atredir", "camp"], t]);
        var A = t = !1;
        f || (l ? (t = m, A = r) : (w && (t = !0), x && (A = !0)));
        s(g, [["atredir", "gopc"], t]);
        s(g, [["atredir", "gopc_err"], A]);
      }

      !p && q && (B("xtor", q), t = new Date().getTime() / 36e5, t = Math.floor(t - e), B("roinbh", 0 <= t ? t : 0));
      p || (t = void 0, t = l ? m ? f || t : f || l : w ? f || t : f || c || t, k && k instanceof Array && -1 < k.indexOf(t) && (t = void 0), t && B("xto", t));

      if (!p && !f) {
        var v;
        l ? r && (v = l) : x && (v = c);
        v && B("pgt", v);
      }

      p || !(v = l ? f || l : f || c || void 0) || !f && !l && w && x || !f && l && m && r || ((!k || k instanceof Array && 0 > k.indexOf(v)) && z("atsession", {
        path: "/",
        session: 60 * a.getConfig("visitLifetime")
      }) && s(g, [["atsession", "histo_camp"], k && k.push(v) ? k : [v]]), q && !d.lastPersistence || !z("atreman", {
        path: "/",
        session: 86400 * d.lifetime
      }) || (s(g, [["atreman", "camp"], v]), s(g, [["atreman", "date"], new Date().getTime() / 36e5])));
      a.emit("Campaigns:" + u, {
        lvl: "INFO"
      });
    };

    (function () {
      var c = ["Cookies", "Utils"];
      if (-1 === ATInternet.Utils.arrayIndexOf(a.getConfig("plgs"), "BackwardCompat")) a.plugins.waitForDependencies(c, function () {
        A("process2:done");
      });else a.onTrigger("BackCampaigns:process2:done", function (f, b) {
        a.plugins.waitForDependencies(c, function () {
          A("process1:done");
        });
      }, !0);
    })();
  };

  window.ATInternet.Tracker.addPlugin("Campaigns");
}).call(window);
(function () {
  var dfltPluginCfg = {
    info: true
  };
  var dfltGlobalCfg = {};

  window.ATInternet.Tracker.Plugins.Clicks = function (a) {
    var d = function (a) {
      var b = "";

      switch (a) {
        case "exit":
          b = "S";
          break;

        case "download":
          b = "T";
          break;

        case "action":
          b = "A";
          break;

        case "navigation":
          b = "N";
      }

      return b;
    },
        g = function (b) {
      var d = b.name;
      a.exec("Utils", "manageChapters", [b, "chapter", 3], function (a) {
        d = a + (d ? d : "");
      });
      return d;
    },
        b = function (b) {
      var r = {
        p: g(b),
        s2: b.level2 || "",
        click: d(b.type) || ""
      },
          l = a.getContext("page") || {};
      r.pclick = g(l);
      r.s2click = l.level2 || "";
      b.customObject && a.exec("Utils", "customObjectToString", [b.customObject], function (a) {
        r.stc = a;
      });
      a.sendHit(r, [["hitType", ["click"]]], b.callback);
    };

    a.click = {};
    a.clickListener = this.clickListener = {};

    a.click.send = this.send = function (d) {
      var g = !0;
      a.plugins.exec("TechClicks", "manageClick", [d.elem, d.event], function (a) {
        g = a;
      });
      b(d);
      return g;
    };

    a.clickListener.send = this.clickListener.send = function (d) {
      if (d.elem) {
        var g;
        a.plugins.exec("TechClicks", "isFormSubmit", [d.elem], function (a) {
          g = a ? "submit" : "click";
        });
        ATInternet.Utils.addEvtListener(d.elem, g, function (l) {
          a.plugins.exec("TechClicks", "manageClick", [d.elem, l]);
          b(d);
        });
      }
    };

    a.click.set = this.set = function (b) {
      a.dispatchSubscribe("click");
      a.setContext("click", {
        name: g(b),
        level2: b.level2 || "",
        customObject: b.customObject
      });
      a.setParam("click", d(b.type) || "", {
        hitType: ["click"]
      });
    };

    a.click.onDispatch = this.onDispatch = function (b) {
      var d = {
        hitType: ["click"]
      };
      a.setParam("p", a.getContext("click").name, d);
      a.setParam("s2", a.getContext("click").level2, d);
      var l = a.getContext("click").customObject;
      l && a.setParam("stc", l, {
        hitType: ["click"],
        encode: !0
      });
      l = a.getContext("page") || {};
      a.setParam("pclick", g(l), d);
      a.setParam("s2click", l.level2 || "", d);
      a.sendHit(null, [["hitType", ["click"]]], b);
      a.setContext("click", void 0);
    };
  };

  window.ATInternet.Tracker.addPlugin("Clicks");
}).call(window);
(function () {
  var dfltPluginCfg = {
    domainAttribution: true,
    info: true
  };
  var dfltGlobalCfg = {
    redirectionLifetime: 30
  };

  window.ATInternet.Tracker.Plugins.ContextVariables = function (a) {
    var d = "",
        g = null,
        b,
        m = "",
        r = "",
        l = {};
    a.configPlugin("ContextVariables", dfltPluginCfg || {}, function (a) {
      l = a;
    });
    a.setConfig("redirectionLifetime", dfltGlobalCfg.redirectionLifetime, !0);

    var k = function (c, b, f) {
      var p = null;
      a.plugins.exec(c, b, f, function (a) {
        p = a;
      });
      return p;
    },
        q = function (a, c) {
      return k("Utils", a, c);
    },
        e = function (c, b) {
      var f = null;
      a.plugins.exec("Cookies", c, b, function (a) {
        f = a;
      });
      return f;
    },
        c = function () {
      a.setParam("hl", function () {
        var a = new Date();
        return a.getHours() + "x" + a.getMinutes() + "x" + a.getSeconds();
      }, {
        permanent: !0,
        hitType: ["all"]
      });
    };

    a.plugins.waitForDependencies(["Cookies", "Utils"], function () {
      m = "set" + (l.domainAttribution ? "" : "Private");
      r = "get" + (l.domainAttribution ? "" : "Private");
      var h = q("getLocation", []);
      d = q("getQueryStringValue", ["xtref", h]);
      void 0 === d && (d = "");
      b = a.getContext("forcedReferer");

      if (a.getConfig("redirect")) {
        var h = q("getDocumentLevel", []),
            h = b ? b : null !== d ? d : h.referrer || "acc_dir",
            n;

        if (n = h) {
          n = {
            path: "/",
            end: a.getConfig("redirectionLifetime")
          };
          var f = e(r, ["atredir"]);
          null !== f ? n = "object" === typeof f && !(f instanceof Array) : (e(m, ["atredir", {}, n]), n = !0);
        }

        n && e(m, [["atredir", "ref"], h]);
      } else {
        g = e(r, [["atredir", "ref"]]);
        e("del", [["atredir", "ref"]]);
        a.setParam("vtag", a.version, {
          permanent: !0,
          hitType: ["all"]
        });
        a.setParam("ptag", "js", {
          permanent: !0,
          hitType: ["all"]
        });
        h = "";

        try {
          h += window.screen.width + "x" + window.screen.height + "x" + window.screen.pixelDepth + "x" + window.screen.colorDepth;
        } catch (p) {}

        a.setParam("r", h, {
          permanent: !0,
          hitType: ["all"]
        });
        h = "";
        window.innerWidth ? h += window.innerWidth + "x" + window.innerHeight : document.body && document.body.offsetWidth && (h += document.body.offsetWidth + "x" + document.body.offsetHeight);
        a.setParam("re", h, {
          permanent: !0,
          hitType: ["all"]
        });
        c();
        a.setParam("lng", navigator.language || navigator.userLanguage, {
          permanent: !0,
          hitType: ["all"]
        });
        h = ATInternet.Utils.uuid().num(13);
        a.setParam("idp", h, {
          permanent: !0,
          hitType: ["page", "clickzone"]
        });
        a.setParam("jv", navigator.javaEnabled() ? "1" : "0", {
          hitType: ["page"]
        });
        h = q("getDocumentLevel", []);
        a.setParam("ref", (b ? b : "acc_dir" === d ? "" : null !== d ? d : "acc_dir" === g ? "" : g ? g : h.referrer).replace(/[<>]/g, "").substring(0, 1600).replace(/&/g, "$"), {
          permanent: !0,
          last: !0,
          hitType: ["page"]
        });
      }

      a.emit("ContextVariables:Ready", {
        lvl: "INFO"
      });
    });
  };

  window.ATInternet.Tracker.addPlugin("ContextVariables");
}).call(window);
(function () {
  var dfltPluginCfg = {
    info: false
  };
  var dfltGlobalCfg = {};

  window.ATInternet.Tracker.Plugins.Cookies = function (a) {
    var d = {},
        g = {
      _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
      encode: function (a) {
        var c = "",
            b,
            e,
            h,
            d,
            n,
            k,
            l = 0;

        for (a = g._utf8_encode(a); l < a.length;) b = a.charCodeAt(l++), e = a.charCodeAt(l++), h = a.charCodeAt(l++), d = b >> 2, b = (b & 3) << 4 | e >> 4, n = (e & 15) << 2 | h >> 6, k = h & 63, isNaN(e) ? n = k = 64 : isNaN(h) && (k = 64), c = c + this._keyStr.charAt(d) + this._keyStr.charAt(b) + this._keyStr.charAt(n) + this._keyStr.charAt(k);

        return c;
      },
      decode: function (a) {
        var c = "",
            b,
            e,
            h,
            d,
            n,
            k = 0;

        for (a = a.replace(/[^A-Za-z0-9\+\/\=]/g, ""); k < a.length;) b = this._keyStr.indexOf(a.charAt(k++)), e = this._keyStr.indexOf(a.charAt(k++)), d = this._keyStr.indexOf(a.charAt(k++)), n = this._keyStr.indexOf(a.charAt(k++)), b = b << 2 | e >> 4, e = (e & 15) << 4 | d >> 2, h = (d & 3) << 6 | n, c += String.fromCharCode(b), 64 != d && (c += String.fromCharCode(e)), 64 != n && (c += String.fromCharCode(h));

        return c = g._utf8_decode(c);
      },
      _utf8_encode: function (a) {
        a = a.replace(/\r\n/g, "\n");

        for (var c = "", b = 0; b < a.length; b++) {
          var e = a.charCodeAt(b);
          128 > e ? c += String.fromCharCode(e) : (127 < e && 2048 > e ? c += String.fromCharCode(e >> 6 | 192) : (c += String.fromCharCode(e >> 12 | 224), c += String.fromCharCode(e >> 6 & 63 | 128)), c += String.fromCharCode(e & 63 | 128));
        }

        return c;
      },
      _utf8_decode: function (a) {
        for (var c = "", b = 0, e, h, d; b < a.length;) e = a.charCodeAt(b), 128 > e ? (c += String.fromCharCode(e), b++) : 191 < e && 224 > e ? (h = a.charCodeAt(b + 1), c += String.fromCharCode((e & 31) << 6 | h & 63), b += 2) : (h = a.charCodeAt(b + 1), d = a.charCodeAt(b + 2), c += String.fromCharCode((e & 15) << 12 | (h & 63) << 6 | d & 63), b += 3);

        return c;
      }
    },
        b = this.getCookie = function (c) {
      return !a.getConfig("disableCookie") && c && "string" === typeof c && (c = RegExp("(?:^| )" + c + "=([^;]+)").exec(document.cookie) || null) ? (c = c[1], c = a.getConfig("base64Storage") ? g.decode(c) : decodeURIComponent(c), c) : null;
    },
        m = this.setCookie = function (c, b, e) {
      if (!a.getConfig("disableCookie") && c && "string" === typeof c && "string" === typeof b) {
        var h = a.getConfig("cookieDomain");
        c += "=";
        b = a.getConfig("base64Storage") ? g.encode(b) : encodeURIComponent(b);
        b = c + b;
        c = !1;

        if (e) {
          c = e.secure;
          var d = e.end,
              n = e.domain;
          e = e.path;
          d && ("function" === typeof d.toGMTString ? b += ";expires=" + d.toGMTString() : "number" === typeof d && (b += ";max-age=" + d.toString()));
          n ? b += ";domain=" + n : h && (b += ";domain=" + h);
          e && "string" === typeof e && (b += ";path=" + e);
        }

        h = a.getConfig("cookieSecure") || c;
        document.cookie = b + (h && "boolean" === typeof h ? ";secure" : "");
        return !0;
      }

      return null;
    },
        r = function (a) {
      var c = null;
      (a = b(a)) && (c = ATInternet.Utils.jsonParse(a));
      return c;
    },
        l = function (a) {
      return m(a.name, ATInternet.Utils.jsonSerialize(a), a.options) ? a : null;
    },
        k = function (a, c, b) {
      var e = null;
      if (!b && d[a]) e = d[a];else if (e = r(a)) e.options.session && l(e), d[a] = e;
      return e ? c ? (a = null, !e || "object" !== typeof e.val || e.val instanceof Array || void 0 === e.val[c] || (a = e.val[c]), a) : e.val : null;
    },
        q = function (a, c, b, e) {
      var h = null;

      if (c) {
        if (h = r(a)) e = h, !e || "object" !== typeof e.val || e.val instanceof Array ? h = null : (e.val[c] = b, h = e), h && (h = l(h));
      } else c = e = e || {}, e = {}, e.name = a, e.val = b, c.session && "number" === typeof c.session && (c.end = c.session), e.options = c, h = l(e);

      h && (d[a] = h);
      return h ? h.val : null;
    },
        e = function (a, c) {
      c ? q(a, c, void 0) : (d[a] = void 0, m(a, "", {
        end: 0
      }));
    };

    a.cookies = {};

    var c = a.cookies.get = this.get = function (a, c) {
      c = c || !1;
      return a instanceof Array && 2 === a.length ? k(a[0], a[1], c) : k(a, void 0, c);
    };

    a.cookies.getPrivate = this.getPrivate = function (b, e) {
      e = e || !1;
      b instanceof Array ? b[0] += a.getConfig("site") : b += a.getConfig("site");
      return c(b, e);
    };

    var h = a.cookies.set = this.set = function (a, c, b) {
      return a instanceof Array ? q(a[0], a[1], c) : q(a, null, c, b);
    };

    a.cookies.setPrivate = this.setPrivate = function (c, b, e) {
      c instanceof Array ? c[0] += a.getConfig("site") : c += a.getConfig("site");
      return h(c, b, e);
    };

    var n = a.cookies.del = this.del = function (a) {
      return a instanceof Array ? e(a[0], a[1]) : e(a);
    };

    a.cookies.delPrivate = this.delPrivate = function (c) {
      c instanceof Array ? c[0] += a.getConfig("site") : c += a.getConfig("site");
      return n(c);
    };

    a.cookies.cacheInvalidation = this.cacheInvalidation = function () {
      d = {};
    };
  };

  window.ATInternet.Tracker.addPlugin("Cookies");
}).call(window);
(function () {
  var dfltPluginCfg = {
    lifetime: 182,
    domainAttribution: true,
    info: true
  };
  var dfltGlobalCfg = {
    redirectionLifetime: 30
  };

  window.ATInternet.Tracker.Plugins.IdentifiedVisitor = function (a) {
    var d = null,
        g = null,
        b = null,
        m = null,
        r = "",
        l = "",
        k = null,
        q = null,
        e = "",
        c = "",
        h = {};
    a.configPlugin("IdentifiedVisitor", dfltPluginCfg || {}, function (a) {
      h = a;
    });
    a.setConfig("redirectionLifetime", dfltGlobalCfg.redirectionLifetime, !0);

    var n = function (c, b, e) {
      var h = null;
      a.plugins.exec(c, b, e, function (a) {
        h = a;
      });
      return h;
    },
        f = function (a, c) {
      return n("Utils", a, c);
    },
        p = function (c, b) {
      var e = null;
      a.plugins.exec("Cookies", c, b, function (a) {
        e = a;
      });
      return e;
    },
        w = function (a, b) {
      var h = p(c, [a]);
      if (null !== h) return "object" === typeof h && !(h instanceof Array);
      p(e, [a, {}, b]);
      return !0;
    },
        x = function (a, c) {
      w("atidvisitor", {
        path: "/",
        session: 86400 * h.lifetime
      }) && p(e, [["atidvisitor", a], c]);
    },
        u = function (c, b) {
      a.setParam(c, b, {
        hitType: ["all"],
        permanent: !0
      });
      x(c, b);
    },
        s = function () {
      var c = function (a, c) {
        /-/.test(c) ? (a.category = c.split("-")[0], a.id = c.split("-")[1]) : a.id = c;
      },
          e = {
        category: "",
        id: ""
      };

      c(e, l || q);
      var h = {
        category: "",
        id: ""
      };
      c(h, r || k);
      h.id ? (h.category && u("ac", h.category), u("at", h.id)) : d && (a.setParam("at", d, {
        hitType: ["all"],
        permanent: !0
      }), b && a.setParam("ac", b, {
        hitType: ["all"],
        permanent: !0
      }));
      e.id ? (e.category && u("ac", e.category), u("an", e.id)) : g && a.setParam("anc", b + "-" + g, {
        hitType: ["all"],
        permanent: !0
      });
    };

    a.plugins.waitForDependencies(["Cookies", "Utils"], function () {
      e = "set" + (h.domainAttribution ? "" : "Private");
      c = "get" + (h.domainAttribution ? "" : "Private");
      var n = f("getLocation", []);
      r = f("getQueryStringValue", ["xtat", n]);
      l = f("getQueryStringValue", ["xtan", n]);
      a.getConfig("redirect") ? (r || l) && w("atredir", {
        path: "/",
        end: a.getConfig("redirectionLifetime")
      }) && (l && p(e, [["atredir", "an"], l]), r && p(e, [["atredir", "at"], r])) : (k = p(c, [["atredir", "at"]]), q = p(c, [["atredir", "an"]]), p("del", [["atredir", "at"]]), p("del", [["atredir", "an"]]), d = p(c, [["atidvisitor", "at"]]), g = p(c, [["atidvisitor", "an"]]), b = p(c, [["atidvisitor", "ac"]]), m = p(c, [["atidvisitor", "vrn"]]), s(), n = "-" + a.getConfig("site") + "-", RegExp(n).test(m) || (m = (m || "") + n, x("vrn", m), n = a.getContext("page") || {}, n.vrn = 1, a.setContext("page", n)));
      a.emit("IdentifiedVisitor:Ready", {
        lvl: "INFO",
        details: {
          cookieRedirectTextual: k,
          cookieRedirectNumeric: q,
          cookieTextual: d,
          cookieNumeric: g,
          cookieCategory: b,
          cookieVrn: m
        }
      });
    });
    a.identifiedVisitor = {};

    a.identifiedVisitor.set = this.set = function (a) {
      var c = a.id;
      a = a.category;
      "number" === typeof c ? u("an", c.toString()) : u("at", c);
      "undefined" !== typeof a && u("ac", a);
    };

    a.identifiedVisitor.unset = this.unset = function () {
      for (var c = ["an", "at", "ac"], b = 0; b < c.length; b++) a.exec("Cookies", "set", [["atidvisitor", c[b]], void 0]), a.delParam(c[b]);

      a.delParam("anc");
    };
  };

  window.ATInternet.Tracker.addPlugin("IdentifiedVisitor");
}).call(window);
(function () {
  var dfltPluginCfg = {
    urlKeyword: "",
    urlResultPageNumber: "",
    urlResultPosition: "",
    info: true
  };
  var dfltGlobalCfg = {};

  window.ATInternet.Tracker.Plugins.InternalSearch = function (a) {
    var d = {};
    a.configPlugin("InternalSearch", dfltPluginCfg || {}, function (a) {
      d = a;
    });

    var g = function (b) {
      var d = {
        np: "undefined" !== typeof b.resultPageNumber ? b.resultPageNumber : "1",
        click: "IS"
      };
      b.hasOwnProperty("keyword") && (d.mc = b.keyword);
      b.hasOwnProperty("resultPosition") && (d.mcrg = b.resultPosition);
      a.sendHit(d, [["hitType", ["InternalSearch"]]], b.callback);
    };

    a.internalSearch = {};

    a.internalSearch.set = this.set = function (b) {
      var d = {},
          g = d;
      b.hasOwnProperty("keyword") && (g.keyword = b.keyword);
      g = d;
      b.hasOwnProperty("resultPageNumber") && (g.resultPageNumber = b.resultPageNumber);
      b = a.getContext("InternalSearch") || {};
      d = ATInternet.Utils.completeFstLevelObj(d, b);
      "undefined" === typeof d.resultPageNumber && (d.resultPageNumber = "1");
      a.setContext("InternalSearch", d);
    };

    a.internalSearch.send = this.send = function (b) {
      var d = !0;
      a.plugins.exec("TechClicks", "manageClick", [b.elem, b.event], function (a) {
        d = a;
      });
      g(b);
      return d;
    };

    a.plugins.waitForDependencies(["Utils"], function () {
      var b;

      if (d.urlKeyword) {
        var g = document.location.href;
        b = {};
        a.plugins.exec("Utils", "getQueryStringValue", [d.urlKeyword, g], function (r) {
          r && (b.keyword = r);
          d.urlResultPageNumber && a.plugins.exec("Utils", "getQueryStringValue", [d.urlResultPageNumber, g], function (a) {
            b.resultPageNumber = a || "1";
          });
        });
      }

      b && a.setContext("InternalSearch", b);
      a.emit("InternalSearch:Ready", {
        lvl: "INFO",
        details: {
          config: {
            urlKeyword: d.urlKeyword,
            urlResultPageNumber: d.urlResultPageNumber
          },
          url: g,
          data: b
        }
      });
    });
  };

  window.ATInternet.Tracker.addPlugin("InternalSearch");
}).call(window);
(function () {
  var dfltPluginCfg = {
    storageCapacity: 1,
    storageMode: "required",
    timeout: 500,
    info: true
  };
  var dfltGlobalCfg = {};

  window.ATInternet.Tracker.Plugins.Offline = function (a) {
    var d = {};
    a.configPlugin("Offline", dfltPluginCfg || {}, function (a) {
      d = a;
    });

    var g = function () {
      var a = localStorage.getItem("ATOffline"),
          c = {
        hits: [],
        length: 0
      };

      if (a) {
        var b = ATInternet.Utils.jsonParse(a) || {
          hits: []
        };
        c.hits = b.hits;
        c.length = a.length;
      }

      return c;
    },
        b = function (a) {
      try {
        localStorage.setItem("ATOffline", ATInternet.Utils.jsonSerialize(a));
      } catch (c) {}
    },
        m = function () {
      return g().hits;
    },
        r = function (a) {
      if (a) {
        a = a.split(/&ref=\.*/i);
        var c = "&cn=offline&olt=" + String(Math.floor(new Date().getTime() / 1e3));
        a = a[0] + c + "&ref=" + (a[1] || "");
      }

      return a;
    },
        l = function (a) {
      var c = g(),
          h = a.length,
          n = !0;

      if (4 * ((c.length || 11) + h) > 1048576 * d.storageCapacity) {
        var n = !1,
            f = c.hits.shift();
        if ("undefined" !== typeof f) for (var n = !0, p = f.length; p < h;) if (f = c.hits.shift(), "undefined" !== typeof f) p += f.length;else {
          n = !1;
          break;
        }
      }

      n && (c.hits.push(a), b({
        hits: c.hits
      }));
    },
        k = function (e, c) {
      if (navigator.onLine) {
        var h = m();

        if (0 < h.length) {
          var n = h.shift();
          b({
            hits: h
          });
          a.onTrigger("Tracker:Hit:Sent:Ok", function () {
            k(e, c);
          }, !0);
          a.onTrigger("Tracker:Hit:Sent:Error", function () {
            k(e, c);
          }, !0);
          a.sendUrl(n);
        } else if (e) {
          var f = null;
          a.plugins.exec("Utils", "getQueryStringValue", ["a", e], function (a) {
            f = a;
          });
          f ? setTimeout(function () {
            a.sendUrl(e, c);
          }, d.timeout) : a.sendUrl(e, c);
        }
      } else e && (l(r(e)), c && c());
    },
        q = function (b) {
      a.builder.sendUrl = function (a, d) {
        b || !navigator.onLine ? (l(r(a)), d && d()) : k(a, d);
      };
    };

    a.offline = {};

    a.offline.getLength = function () {
      return 4 * g().length;
    };

    a.offline.remove = function () {
      b({
        hits: []
      });
    };

    a.offline.get = m;

    a.offline.send = function () {
      k(null, null);
    };

    a.plugins.waitForDependencies(["Utils"], function () {
      var b;

      try {
        var c = window.localStorage;
        c.setItem("__storage_test__", "__storage_test__");
        c.removeItem("__storage_test__");
        b = !0;
      } catch (h) {
        b = !1;
      }

      c = navigator.onLine;
      b && "undefined" !== typeof c && ("required" === d.storageMode ? q(!1) : "always" === d.storageMode && q(!0));
      a.emit("Offline:Ready", {
        lvl: "INFO",
        details: {
          isLocalStorageAvailable: b,
          storageMode: d.storageMode,
          isOnline: c
        }
      });
    });
  };

  window.ATInternet.Tracker.addPlugin("Offline");
}).call(window);
(function () {
  var dfltPluginCfg = {
    info: true
  };
  var dfltGlobalCfg = {};

  window.ATInternet.Tracker.Plugins.OnSiteAds = function (a) {
    var d = "",
        g = function (c) {
      var b = c.name;
      a.exec("Utils", "manageChapters", [c, "chapter", 3], function (a) {
        b = a + (b ? b : "");
      });
      return b;
    },
        b = function (a, b) {
      return a[b] ? a[b] : "";
    },
        m = function (a, e) {
      var d = b(a, e);

      if (d) {
        var f = b(a, "prefix");

        if (d.campaignId) {
          var f = f || "PUB",
              p = b(d, "campaignId"),
              k = b(d, "creation"),
              g = b(d, "variant"),
              l = b(d, "format"),
              q = b(d, "generalPlacement"),
              m = b(d, "detailedPlacement"),
              r = b(d, "advertiserId"),
              d = b(d, "url");
          return f + "-" + p + "-" + k + "-" + g + "-" + l + "-" + q + "-" + m + "-" + r + "-" + d;
        }

        if (d.adId) return f = f || "INT", p = b(d, "adId"), k = b(d, "format"), d = b(d, "productId"), f + "-" + p + "-" + k + "||" + d;
      }

      return "";
    },
        r = function (c) {
      var b = a.getContext("page") || {};
      a.sendHit({
        atc: m(c, "click"),
        type: "AT",
        patc: g(b),
        s2atc: b.level2 || "",
        stc: c.customObject || ""
      }, [["hitType", ["onSiteAdsClick"]]], c.callback);
    },
        l = function (c) {
      a.sendHit({
        ati: m(c, "impression"),
        type: "AT",
        stc: c.customObject || ""
      }, [["hitType", ["onSiteAdsImpression"]]], c.callback);
    },
        k = function (c, b) {
      var e = a.buffer.get("ati", !0) || {};
      e.value = "string" === typeof e.value ? [e.value] : e.value || [];
      e.options = e.options || {
        hitType: [b, "page"]
      };
      e.value.push(c);
      a.buffer.set("ati", e.value, e.options);
    },
        q = function (c, b) {
      c.click ? a.setParam("atc", m(c, "click"), {
        hitType: [b, "page"]
      }) : c.impression && a.setParam("ati", m(c, "impression"), {
        hitType: [b, "page"]
      });

      if (c.customObject) {
        a.setContext("onsiteads", {
          customObject: c.customObject
        });
        var e = a.getContext("page") || {};
        e.customObject = ATInternet.Utils.completeFstLevelObj(e.customObject, c.customObject, !1);
        a.setContext("page", e);
      }

      a.dispatchSubscribe("onSiteAds");
    };

    a.selfPromotion = this.selfPromotion = {};
    a.publisher = this.publisher = {};

    a.publisher.set = this.publisher.set = function (a) {
      q(a, "publisher");
    };

    a.selfPromotion.set = this.selfPromotion.set = function (a) {
      q(a, "selfPromotion");
    };

    a.publisher.add = this.publisher.add = function (c) {
      k(m(c, "impression"), "publisher");
      a.dispatchSubscribe("onSiteAds");
    };

    a.selfPromotion.add = this.selfPromotion.add = function (c) {
      k(m(c, "impression"), "selfPromotion");
      a.dispatchSubscribe("onSiteAds");
    };

    var e = this.advertEvent = function (c) {
      var b = !0;
      a.exec("TechClicks", "manageClick", [c.elem, c.event], function (a) {
        b = a;
      });
      c.click ? r(c) : c.impression && l(c);
      return b;
    };

    a.publisher.send = this.publisher.send = function (a) {
      return e(a);
    };

    a.selfPromotion.send = this.selfPromotion.send = function (a) {
      return e(a);
    };

    a.onSiteAds = {};

    a.onSiteAds.onDispatch = this.onDispatch = function (c) {
      if (!a.dispatchSubscribed("page")) {
        var b = a.getContext("page") || {};
        a.setParam("type", "AT", {
          hitType: ["publisher", "selfPromotion"]
        });
        a.getParam("atc") && (a.setParam("patc", g(b), {
          hitType: ["publisher", "selfPromotion"]
        }), a.setParam("s2atc", b.level2 || "", {
          hitType: ["publisher", "selfPromotion"]
        }));
        (b = a.getContext("onsiteads")) && b.customObject && a.setParam("stc", b.customObject, {
          encode: !0,
          hitType: ["publisher", "selfPromotion"]
        });
        a.sendHit(null, [["hitType", ["publisher", "selfPromotion"]]], c);
      }
    };

    a.plugins.waitForDependencies(["Utils", "TechClicks"], function () {
      d = document.location.href;
      a.plugins.exec("Utils", "getQueryStringValue", ["xtatc", d], function (c) {
        c && a.setParam("atc", c, {
          hitType: ["publisher", "selfPromotion", "page"]
        });
      });
      a.emit("OnSiteAds:Ready", {
        lvl: "INFO",
        details: {
          href: d
        }
      });
    });
  };

  window.ATInternet.Tracker.addPlugin("OnSiteAds");
}).call(window);
(function () {
  var dfltPluginCfg = {
    info: true
  };
  var dfltGlobalCfg = {};

  window.ATInternet.Tracker.Plugins.Page = function (a) {
    var d = ["pageId", "chapterLabel", "update"],
        g = ["pid", "pchap", "pidt"],
        b = ["page", "site"],
        m = ["f", "x"],
        r = function (c) {
      var b = c.name;
      a.exec("Utils", "manageChapters", [c, "chapter", 3], function (a) {
        b = a + (b ? b : "");
      });
      return b;
    },
        l = function (a, c, b) {
      c ? a = c : a || "undefined" === typeof b || (a = b);
      return a;
    },
        k = function (a, c, b) {
      c.hasOwnProperty(b) && (a[b] = l(a[b], c[b]));
    },
        q = function (c) {
      if (!ATInternet.Utils.isPreview() || a.getConfig("preview")) ATInternet.Utils.isPrerender(function (a) {
        c(a);
      }) || c();
    },
        e = function (c, e, d) {
      if (e) for (var h = 0; h < b.length; h++) if (e.hasOwnProperty(b[h]) && e[b[h]]) for (var f in e[b[h]]) e[b[h]].hasOwnProperty(f) && (d ? c[m[h] + f] = e[b[h]][f] : a.setParam(m[h] + f, e[b[h]][f]));
    },
        c = function (c, b, e) {
      if (b) {
        a.exec("Utils", "manageChapters", [b, "chapter", 3], function (a) {
          a && (b.chapterLabel = a.replace(/::$/gi, ""));
        });

        for (var h = 0; h < g.length; h++) b.hasOwnProperty(d[h]) && (e ? c[g[h]] = b[d[h]] : a.setParam(g[h], b[d[h]]));
      }
    },
        h = function (c, b, e) {
      if (b && b.keywords instanceof Array) {
        var d = b.keywords.length;

        if (0 < d) {
          for (var h = "", f = 0; f < d; f++) h += "[" + b.keywords[f] + "]" + (f < d - 1 ? "|" : "");

          e ? c.tag = h : a.setParam("tag", h);
        }
      }
    },
        n = function (c, b, e) {
      if (b) {
        var d,
            h = function (a) {
          return a ? a : "0";
        };

        d = "" + (h(b.category1) + "-");
        d += h(b.category2) + "-";
        d += h(b.category3);
        e ? c.ptype = d : a.setParam("ptype", d);
      }
    },
        f = function (c, b, e) {
      if (b) for (var d in b) b.hasOwnProperty(d) && "undefined" !== typeof b[d] && (e ? c[d] = b[d] : a.setParam(d, b[d]));
    };

    a.customVars = this.customVars = {};

    a.customVars.set = this.customVars.set = function (c) {
      var b = a.getContext("page") || {},
          e = b.customVars;

      if (e) {
        if (c) for (var d in c) c.hasOwnProperty(d) && (e[d] = ATInternet.Utils.completeFstLevelObj(e[d], c[d], !0));
      } else e = c;

      b.customVars = e;
      a.setContext("page", b);
    };

    a.dynamicLabel = this.dynamicLabel = {};

    a.dynamicLabel.set = this.dynamicLabel.set = function (c) {
      var b = a.getContext("page") || {};
      b.dynamicLabel = ATInternet.Utils.completeFstLevelObj(b.dynamicLabel, c, !0);
      a.setContext("page", b);
    };

    a.tags = this.tags = {};

    a.tags.set = this.tags.set = function (c) {
      var b = a.getContext("page") || {};
      b.tags = ATInternet.Utils.completeFstLevelObj(b.tags, c, !0);
      a.setContext("page", b);
    };

    a.customTreeStructure = this.customTreeStructure = {};

    a.customTreeStructure.set = this.customTreeStructure.set = function (c) {
      var b = a.getContext("page") || {};
      b.customTreeStructure = ATInternet.Utils.completeFstLevelObj(b.customTreeStructure, c, !0);
      a.setContext("page", b);
    };

    a.page = {};

    a.page.reset = this.reset = function () {
      a.setContext("page", void 0);
    };

    a.page.set = this.set = function (c) {
      a.dispatchSubscribe("page");
      var b = a.getContext("page") || {};
      b.name = l(b.name, c.name, "");
      b.level2 = l(b.level2, c.level2, "");
      k(b, c, "chapter1");
      k(b, c, "chapter2");
      k(b, c, "chapter3");
      b.customObject = ATInternet.Utils.completeFstLevelObj(b.customObject, c.customObject, !0);
      a.setContext("page", b);
    };

    a.page.send = this.send = function (b) {
      var d = {
        p: r(b),
        s2: b.level2 || ""
      },
          g = !0,
          m = b.customObject;

      if (m) {
        var s = a.getParam("stc", !0);

        if (s && s.options.permanent) {
          var y = s.options.hitType || [],
              z = ATInternet.Utils.arrayIndexOf;
          if (-1 !== z(y, "page") || -1 !== z(y, "all")) m = ATInternet.Utils.completeFstLevelObj(s.value || {}, m, !0);
        }

        a.exec("Utils", "customObjectToString", [m], function (a) {
          d.stc = a;
        });
      }

      m = a.getContext("page") || {};
      m.vrn && (d.vrn = m.vrn, m.vrn = void 0, a.setContext("page", m));
      s = a.getContext("InternalSearch") || {};
      "undefined" != typeof s.keyword && (d.mc = ATInternet.Utils.cloneSimpleObject(s.keyword), "undefined" != typeof s.resultPageNumber && (d.np = ATInternet.Utils.cloneSimpleObject(s.resultPageNumber)), a.setContext("InternalSearch", void 0));
      ATInternet.Utils.isPreview() && a.getConfig("preview") && (d.pvw = 1);
      e(d, b.customVars, !0);
      c(d, b.dynamicLabel, !0);
      h(d, b.tags, !0);
      n(d, b.customTreeStructure, !0);
      s = a.getContext("campaigns") || {};
      f(d, s, !0);
      a.setContext("campaigns", void 0);
      a.exec("TechClicks", "manageClick", [b.elem, b.event], function (a) {
        g = a;
      });
      q(function () {
        a.sendHit(d, null, b.callback);
      });
      m.name = l(m.name, b.name, "");
      m.level2 = l(m.level2, b.level2, "");
      k(m, b, "chapter1");
      k(m, b, "chapter2");
      k(m, b, "chapter3");
      a.setContext("page", m);
      return g;
    };

    a.page.onDispatch = this.onDispatch = function (b) {
      var d = a.getContext("page") || {},
          k = a.getContext("InternalSearch") || {};
      a.setParam("p", r(d));
      a.setParam("s2", d.level2 || "");
      d.vrn && (a.setParam("vrn", d.vrn), d.vrn = void 0, a.setContext("page", d));
      "undefined" != typeof k.keyword && (a.setParam("mc", ATInternet.Utils.cloneSimpleObject(k.keyword)), "undefined" != typeof k.resultPageNumber && a.setParam("np", ATInternet.Utils.cloneSimpleObject(k.resultPageNumber)), a.setContext("InternalSearch", void 0));
      ATInternet.Utils.isPreview() && a.getConfig("preview") && a.setParam("pvw", 1);
      e(null, d.customVars, !1);
      c(null, d.dynamicLabel, !1);
      h(null, d.tags, !1);
      n(null, d.customTreeStructure, !1);
      k = a.getContext("campaigns") || {};
      f(null, k, !1);
      a.setContext("campaigns", void 0);
      var d = d.customObject,
          g = [["hitType", ["page"]]];
      if (d) {
        if (k = a.getParam("stc", !0)) {
          var l = k.options.hitType || [],
              m = ATInternet.Utils.arrayIndexOf,
              m = -1 !== m(l, "page") || -1 !== m(l, "all"),
              l = k.options.permanent;
          m ? (m = ATInternet.Utils.cloneSimpleObject(k), m.value = ATInternet.Utils.completeFstLevelObj(m.value || {}, d, !0), a.setParam("stc", m.value, {
            encode: !0
          }), q(function () {
            a.sendHit(null, g, b);
          }), l && a.setParam("stc", k.value, k.options)) : (a.setParam("stc", d, {
            encode: !0
          }), q(function () {
            a.sendHit(null, g, b);
          }), a.setParam("stc", k.value, k.options));
        } else a.setParam("stc", d, {
          encode: !0
        }), q(function () {
          a.sendHit(null, g, b);
        });
      } else q(function () {
        a.sendHit(null, g, b);
      });
    };
  };

  window.ATInternet.Tracker.addPlugin("Page");
}).call(window);
(function () {
  var dfltPluginCfg = {
    info: true
  };
  var dfltGlobalCfg = {};

  window.ATInternet.Tracker.Plugins.RichMedia = function (a) {
    var d = function (a, b) {
      var e = parseInt(a, 10);
      return e ? Math.max(e, b) : 0;
    },
        g = new function () {
      this._timeout = {};

      this.setTimeout = function (c, b, e) {
        this._timeout[c] = this._timeout[c] || {};
        window.clearTimeout(this._timeout[c][b]);
        this._timeout[c][b] = window.setTimeout(function () {
          a.richMedia.send({
            action: "refresh",
            playerId: c,
            mediaLabel: b
          });
        }, 1e3 * e);
      };

      this.setTimeoutObject = function (c, b, e, f) {
        this._timeout[c] = this._timeout[c] || {};

        if ("undefined" === typeof this._timeout[c][b]) {
          var k = [],
              g;

          for (g in e) e.hasOwnProperty(g) && k.push({
            delay: d(g, 0),
            refresh: d(e[g], 5)
          });

          k.sort(function (a, c) {
            return a.delay < c.delay ? -1 : a.delay > c.delay ? 1 : 0;
          });
          this._timeout[c][b] = {
            arrObj: k,
            arrObjSave: ATInternet.Utils.cloneSimpleObject(k)
          };
        }

        e = this._timeout[c][b];

        if (0 < e.arrObj.length && (k = e.arrObj[0].delay, g = e.arrObj[0].refresh, "number" === typeof k && "number" === typeof g && 0 < g)) {
          e[k] = e[k] || {};
          var l = void 0;
          "undefined" !== typeof e.arrObj[1] && (l = e.arrObj[1].delay);
          var m = 0;
          "undefined" === typeof l ? m = 1 : "number" === typeof e[k].num ? m = "refresh" === f ? Math.max(e[k].num - 1, 0) : e[k].num : "number" === typeof l && (m = Math.floor(60 * (l - k) / g) - 1);
          e[k].num = m;
          window.clearTimeout(e[k].timeout);
          0 < m ? e[k].timeout = window.setTimeout(function () {
            a.richMedia.send({
              action: "refresh",
              playerId: c,
              mediaLabel: b
            });
          }, 1e3 * g) : (e[k].num = void 0, e[k].timeout = void 0, e.arrObj.splice(0, 1), window.setTimeout(function () {
            a.richMedia.send({
              action: "refresh",
              playerId: c,
              mediaLabel: b
            });
          }, 1e3 * g));
          this._timeout[c][b] = e;
        }
      };

      this.clearTimeout = function (a, b, e) {
        this._timeout[a] = this._timeout[a] || {};

        if ("object" === typeof this._timeout[a][b]) {
          for (var d in this._timeout[a][b]) if (this._timeout[a][b].hasOwnProperty(d)) {
            var k = this._timeout[a][b][d].num;
            "undefined" !== typeof k && 0 < k && (window.clearTimeout(this._timeout[a][b][d].timeout), this._timeout[a][b][d].timeout = void 0);
          }

          e && (this._timeout[a][b].arrObj = ATInternet.Utils.cloneSimpleObject(this._timeout[a][b].arrObjSave));
        } else window.clearTimeout(this._timeout[a][b]);
      };

      this.removePlayer = function (c) {
        for (var b in this._timeout[c]) this._timeout[c].hasOwnProperty(b) && (this.clearTimeout(c, b, !1), a.richMedia.send({
          action: "stop",
          playerId: c,
          mediaLabel: b
        }));

        this._timeout[c] = {};
      };

      this.removeAll = function () {
        for (var a in this._timeout) this._timeout.hasOwnProperty(a) && this.removePlayer(a);

        this._timeout = {};
      };
    }(),
        b = new function () {
      this._media = function () {
        this.type = void 0;
        this.plyr = 0;
        this.clnk = this.s2 = void 0;
        this.p = "";
        this.m9 = this.m6 = this.m5 = this.m1 = this.rfsh = this.buf = this.a = void 0;
      };

      this._mediaAll = {};

      this.setMediaProperty = function (a, b, e, d) {
        this._mediaAll[a] = this._mediaAll[a] || {};
        this._mediaAll[a][b] = this._mediaAll[a][b] || new this._media();
        this._mediaAll[a][b][e] = d;
      };

      this.getMediaProperty = function (a, b, e) {
        if (this._mediaAll[a] && this._mediaAll[a][b]) return this._mediaAll[a][b][e];
      };

      this.removePlayer = function (a) {
        this._mediaAll[a] = {};
      };

      this.removeAll = function () {
        this._mediaAll = {};
      };
    }(),
        m = function (b, e, d) {
      var k = b[d] || "";
      a.exec("Utils", "manageChapters", [b, e, 3], function (a) {
        k = a + k;
      });
      return k;
    },
        r = function (a, b, e, d) {
      var k = a[b];
      "boolean" === typeof a[b] && (k = a[b] ? d : e);
      return k;
    },
        l = function (a) {
      var b = 0;
      /^(\-|\+)?([0-9]+)$/.test(a) && (b = Number(a));
      return b;
    },
        k = function (a, e, d, k, g) {
      e = b.getMediaProperty(e, d, k);
      "undefined" !== typeof e && (a[k] = g ? encodeURIComponent(e) : e);
    },
        q = function (a, b, e) {
      "undefined" !== typeof e && (a[b] = e);
    },
        e = function (a, e, d, k) {
      "undefined" !== typeof k && b.setMediaProperty(a, e, d, k);
    };

    a.richMedia = {};

    a.richMedia.add = this.add = function (a) {
      a = a || {};
      var b = l(a.playerId),
          d = m(a, "mediaTheme", "mediaLabel"),
          k = r(a, "isEmbedded", "int", "ext");
      e(b, d, "plyr", b);
      e(b, d, "type", a.mediaType);
      e(b, d, "s2", a.mediaLevel2);
      e(b, d, "p", d);
      e(b, d, "clnk", a.linkedContent || a.previousMedia);
      e(b, d, "a", a.action);
      e(b, d, "rfsh", a.refreshDuration);
      e(b, d, "m1", a.duration);
      e(b, d, "m5", k);
      e(b, d, "m6", a.broadcastMode);
      e(b, d, "m9", a.webdomain);
    };

    a.richMedia.remove = this.remove = function (a) {
      g.removePlayer(a);
      b.removePlayer(a);
    };

    a.richMedia.removeAll = this.removeAll = function () {
      g.removeAll();
      b.removeAll();
    };

    a.richMedia.send = this.send = function (c) {
      c = c || {};
      var e = l(c.playerId),
          n = m(c, "mediaTheme", "mediaLabel"),
          f = {};
      f.plyr = e;
      f.p = n;
      c.action && (f.a = c.action);
      k(f, e, n, "type", !1);
      k(f, e, n, "s2", !1);
      k(f, e, n, "m1", !1);
      k(f, e, n, "m5", !1);
      k(f, e, n, "m6", !1);

      if ("play" === f.a || "info" === f.a) {
        c = r(c, "isBuffering", "0", "1");
        q(f, "buf", c);
        c = a.getContext("page") || {};
        var p = m(c, "chapter", "name") || void 0;
        q(f, "prich", p);
        q(f, "s2rich", c.level2 || void 0);
        k(f, e, n, "clnk", !1);
        k(f, e, n, "m9", !0);
      }

      a.sendHit(f, [["hitType", ["richmedia"]]]);
      "pause" === f.a ? g.clearTimeout(e, n, !1) : "stop" === f.a && g.clearTimeout(e, n, !0);
      if ("play" === f.a || "refresh" === f.a) c = b.getMediaProperty(e, n, "rfsh"), "object" === typeof c ? g.setTimeoutObject(e, n, c, f.a) : (f = d(c, 5), 0 !== f && g.setTimeout(e, n, f));
    };
  };

  window.ATInternet.Tracker.addPlugin("RichMedia");
}).call(window);
(function () {
  var dfltPluginCfg = {
    clicksAutoManagementEnabled: true,
    clicksAutoManagementTimeout: 500,
    info: false
  };
  var dfltGlobalCfg = {};

  window.ATInternet.Tracker.Plugins.TechClicks = function (a) {
    var d, g;
    a.configPlugin("TechClicks", dfltPluginCfg || {}, function (a) {
      d = a.clicksAutoManagementEnabled;
      g = a.clicksAutoManagementTimeout;
    });

    this.deactivateAutoManagement = function () {
      d = !1;
    };

    var b = function (a) {
      switch (a.target) {
        case "_top":
          window.top.location.href = a.url;
          break;

        case "_parent":
          window.parent.location.href = a.url;
          break;

        default:
          window.location.href = a.url;
      }
    },
        m = function (a) {
      var c = a.timeout;
      a.mailto ? setTimeout(function () {
        window.location.href = a.mailto;
      }, c) : a.form ? setTimeout(function () {
        a.form.submit();
      }, c) : a.url && setTimeout(function () {
        b({
          url: a.url,
          target: a.target
        });
      }, c);
    },
        r = function (d) {
      for (var c, h = "_self", k = d.timeoutonly; d;) {
        if (d.href && 0 === d.href.indexOf("http")) {
          c = d.href.split('"').join('\\"');
          h = d.target ? d.target : h;
          break;
        }

        d = d.parentNode;
      }

      if (c) {
        if (!k) a.onTrigger("Tracker:Hit:Sent:Ok", function () {
          b({
            url: c,
            target: h
          });
        });
        m({
          url: c,
          target: h,
          timeout: g
        });
      }
    },
        l = function (b) {
      var c = b;

      for (b = c.timeoutonly; c && "FORM" !== c.nodeName;) c = c.parentNode;

      if (c) {
        if (!b) a.onTrigger("Tracker:Hit:Sent:Ok", function () {
          c.submit();
        });
        m({
          form: c,
          timeout: g
        });
      }
    },
        k = function (b) {
      var c = b;

      for (b = c.timeoutonly; c && !(c.href && 0 <= c.href.indexOf("mailto:"));) c = c.parentNode;

      if (c) {
        if (!b) a.onTrigger("Tracker:Hit:Sent:Ok", function () {
          window.location.href = c.href;
        });
        m({
          mailto: c.href,
          timeout: g
        });
      }
    },
        q = function (a) {
      for (; a;) {
        if (a.href) {
          if (0 <= a.href.indexOf("mailto:")) return "mailto";
          if (0 === a.href.indexOf("http")) return "redirection";
        } else if ("FORM" === a.nodeName) return "form";

        a = a.parentNode;
      }

      return "";
    };

    this.manageClick = function (a, b) {
      var h = !0;

      if (d && a) {
        var g;

        a: {
          for (g = a; g;) {
            if ("function" === typeof g.getAttribute && ("_blank" === g.getAttribute("target") || "no" === g.getAttribute("data-atclickmanagement"))) {
              g = !0;
              break a;
            }

            g = g.parentNode;
          }

          g = a;

          for (var f = window.location.href, m; g;) {
            if ((m = g.href) && 0 <= m.indexOf("#") && f.substring(0, 0 <= f.indexOf("#") ? f.indexOf("#") : f.length) === m.substring(0, m.indexOf("#"))) {
              g = !0;
              break a;
            }

            g = g.parentNode;
          }

          g = !1;
        }

        f = q(a);
        if (!g && f) switch (f) {
          case "mailto":
            k(a);
            h = !1;
            break;

          case "form":
            l(a);
            h = !1;
            break;

          case "redirection":
            r(a), h = !1;
        }
      }

      b && (g = b.defaultPrevented, "function" === typeof b.isDefaultPrevented && (g = b.isDefaultPrevented()), g || b.preventDefault && b.preventDefault());
      return h;
    };
  };

  window.ATInternet.Tracker.addPlugin("TechClicks");
}).call(window);
(function () {
  var dfltPluginCfg = {
    info: false
  };
  var dfltGlobalCfg = {};

  window.ATInternet.Tracker.Plugins.Utils = function (a) {
    var d = this,
        g = {};

    d.getQueryStringValue = function (a, b) {
      var d = window.ATInternet.Utils.hashcode(b).toString();

      if (!g[d]) {
        g[d] = {};
        var k = RegExp("[&#?]{1}([^&=#?]*)=([^&#]*)?", "g"),
            q = k.exec(b);
        if (null !== q) for (; null !== q;) g[d][q[1]] = q[2], q = k.exec(b);
      }

      return g[d].hasOwnProperty(a) ? g[d][a] : null;
    };

    d.customObjectToString = function (a) {
      return encodeURIComponent(window.ATInternet.Utils.jsonSerialize(a));
    };

    d.manageChapters = function (b, d, g) {
      var k = a.getConfig("ignoreEmptyChapterValue"),
          q = "";

      if (b) {
        g = parseInt(g, 10);

        for (var e = 1; e < g + 1; e++) var c = b[d + e] || "", q = k ? q + (c ? c + "::" : "") : q + (b.hasOwnProperty(d + e) ? c + "::" : "");
      }

      return q;
    };

    d.getDocumentLevel = function () {
      var b = a.getConfig("documentLevel");
      if (0 > b.indexOf(".")) return window[b] || document;
      b = b.split(".");
      return window[b[0]][b[1]] || document;
    };

    d.getLocation = function () {
      return d.getDocumentLevel().location.href;
    };

    a.dispatchIndex = {};
    a.dispatchStack = [];
    a.dispatchEventFor = {};
    var b = 0;

    a.dispatchSubscribe = function (b) {
      return a.dispatchIndex[b] ? !1 : (a.dispatchStack.push(b), a.dispatchIndex[b] = !0);
    };

    a.dispatchSubscribed = function (b) {
      return !0 === a.dispatchIndex[b];
    };

    a.addSpecificDispatchEventFor = function (d) {
      return a.dispatchEventFor[d] ? !1 : (a.dispatchEventFor[d] = !0, b++, !0);
    };

    a.processSpecificDispatchEventFor = function (d) {
      a.dispatchEventFor[d] && (a.dispatchEventFor[d] = !1, b--, 0 === b && (a.dispatchEventFor = {}, a.emit("Tracker:Plugin:SpecificEvent:Exec:Complete", {
        lvl: "INFO"
      })));
    };

    a.dispatch = function (d) {
      var g = function () {
        for (var b = "", g = null; 0 < a.dispatchStack.length;) b = a.dispatchStack.pop(), 0 === a.dispatchStack.length && (g = d), a[b].onDispatch(g);

        a.dispatchIndex = {};
        a.delContext(void 0, "customObject");
      },
          l = function () {
        if (a.plugins.isExecWaitingLazyloading()) a.onTrigger("Tracker:Plugin:Lazyload:Exec:Complete", function () {
          g();
        }, !0);else g();
      };

      if (0 === b) l();else a.onTrigger("Tracker:Plugin:SpecificEvent:Exec:Complete", function () {
        l();
      }, !0);
    };

    a.dispatchRedirect = function (b) {
      var d = !0,
          g = null;
      b && (b.elem && (b.elem.timeoutonly = !0, a.plugins.exec("TechClicks", "manageClick", [b.elem, b.event], function (a) {
        d = a;
      })), g = b.callback);
      a.dispatch(g);
      return d;
    };
  };

  window.ATInternet.Tracker.addPlugin("Utils");
}).call(window);

if (typeof window.ATInternet.onTrackerLoad === "function") {
  window.ATInternet.onTrackerLoad();
}
