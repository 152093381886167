import React, { useEffect } from 'react';
import { PageWrapper } from '../../app/styles';
import BreakingNewsWidget from '../../widgets/breakingNews/BreakingNewsWidget';
import SwiperWidget from '../../widgets/swiper/SwiperWidget';
import BannerWidget from '../../widgets/banner/BannerWidget';
import { Loading } from '@ard-online/component-styleguide';
import useFetch from '../../../services/fetch/useFetch';
import env from '../../../configs/env';
import HeaderWidget from '../../widgets/header/HeaderWidget';
import { sendPageImpression } from '../../../services/tracking';
import { PAGE_TITLES } from '../../../configs/types';
import { Item } from '../../../types';
import { cx } from '@linaria/core';

function StartPage() {
	/** Variables */

	const data: Array<Item> = useFetch(env.API_HOST, 'json', (res) => res.feed);
	const tagesschauData = data.length ? data.find((item) => item.id === 'tagesschau') : undefined;
	const tagesschauFeed = tagesschauData ? tagesschauData.feed : undefined;

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		sendPageImpression(PAGE_TITLES.HOME);
	}, []);

	/** JSX */
	return (
		<div>
			<HeaderWidget data={data.length ? data : undefined} />

			<main className={cx(PageWrapper.base)}>
				{data.length ? (
					<>
						{tagesschauFeed && <BreakingNewsWidget feed={tagesschauFeed} />}

						{data.map((item, i) => {
							if (item.feed) {
								return <SwiperWidget {...item} widgetIndex={i} key={i} />;
							} else {
								return <BannerWidget {...item} widgetIndex={i} key={i} />;
							}
						})}
					</>
				) : (
					<Loading height="300" />
				)}
			</main>
		</div>
	);
}

export default StartPage;
