import { TEASER_TYPES } from '../../configs/types';
import { TeaserTypes } from '@ard-online/component-styleguide/dist/components/Teaser/Teaser';

const teaserTypeMap: Record<string, TEASER_TYPES> = {
	live: TEASER_TYPES.LIVE,
	show: TEASER_TYPES.SHOW,
	ondemand: TEASER_TYPES.MEDIA,
	poster: TEASER_TYPES.MEDIA,
	compilation: TEASER_TYPES.COLLECTION,
};
export function mapTeaserType(type: string): TeaserTypes {
	return (teaserTypeMap[type] as TeaserTypes) || (TEASER_TYPES.MEDIA as TeaserTypes);
}
