import type { ReactNode } from 'react';
import React, { useState, useContext } from 'react';
import type { PictureCreditProp } from './types';

type Props = {
	children: ReactNode;
};

function doNothing(): void {
	// do nothing
}

export const PictureCreditContext = React.createContext<PictureCreditContextvalue>({
	pictureCredits: [],
	addPictureCredit: doNothing,
});

export type PictureCreditContextvalue = {
	pictureCredits: Array<PictureCreditProp>;
	addPictureCredit: (value: PictureCreditProp) => void;
};

export function usePictureCredits() {
	return useContext(PictureCreditContext);
}

function PictureCreditService({ children }: Props) {
	const [state, setState] = useState<PictureCreditProp[]>([]);

	function addPictureCredit(pictureCredit: PictureCreditProp) {
		setState((prevState) => {
			return [...prevState, pictureCredit];
		});
	}

	return (
		<PictureCreditContext.Provider
			value={{
				pictureCredits: state,
				addPictureCredit,
			}}
		>
			{children}
		</PictureCreditContext.Provider>
	);
}

export default PictureCreditService;
