import type { AnyObject } from '../../types';
const MAX_NUMBER_OF_ITEMS = 20;

export type BreakingNewsItem = {
	id: string;
	type: string;
	href: string;
	textPosition: string;
	textAlignment: string;
	label?: string;
	overline: string;
	title: string;
	teasertext: string;
	date: string;
};

export default function mapXMLBreakingNews(jsonData: AnyObject, label?: string) {
	const items = jsonData.RDF.item;
	const mappedItems: Array<BreakingNewsItem> = [];
	if (!items) {
		return mappedItems;
	}

	const maxItemsReached = () => mappedItems.length >= MAX_NUMBER_OF_ITEMS;

	for (let i = 0; i < items.length && !maxItemsReached(); i++) {
		const item = items[i];

		if (item.keywords && item.keywords.includes('Eilmeldung')) {
			mappedItems.push({
				id: item.link,
				type: item.type,
				href: item.link,
				textPosition: 'onImage',
				textAlignment: 'center',
				label: label,
				overline: item.topline,
				title: item.title,
				teasertext: item.description,
				date: item.date,
			});
		}
	}

	return mappedItems;
}
