import { CONNECTION_TYPES, PAGE_TITLES } from '../../configs/types';
import { AnyObject } from '../../types';
export function encodeForTracking(value?: string) {
	if (value) {
		return value.replace(/&/g, 'und').replace(/\n/g, '').replace(/#/g, '').replace(/\?/g, '.').replace(/\\/g, "'");
	}

	return '';
}
export function sendOfflineTracking(tag: AnyObject) {
	function send() {
		tag.offline.send();
		window.removeEventListener(CONNECTION_TYPES.ONLINE, send);
	}

	if (!window.navigator.onLine) {
		window.addEventListener(CONNECTION_TYPES.ONLINE, send);
	}
}
export function mapPageTitleToChapterOne(pageTitle: PAGE_TITLES) {
	const pageTitleChapterOneMap = {
		[PAGE_TITLES.SETTINGS]: 'Service',
		[PAGE_TITLES.HOME]: 'Startseite',
	};
	return pageTitleChapterOneMap[pageTitle] || '';
}
