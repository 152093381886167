import { css } from '@linaria/core';
import { Root as ClassicStage } from '@ard-online/component-styleguide/dist/components/ClassicStage/styles';
import { RowRoot } from '@ard-online/component-styleguide';

//section
export const Root = {
	base: css`
		margin-bottom: 50px;
		overflow: hidden;

		.${RowRoot.base} {
			margin-top: 0;
		}

		.${ClassicStage.base} {
			margin-top: 10px;
		}

		h3 {
			color: #fff;
		}

		.swiper-scrollbar {
			display: none;
		}

		.swiper-pagination .swiper-pagination-bullet {
			&:before {
				background-color: rgba(var(--brand-dark-secondary), 0.5);
			}
		}

		.swiper-pagination .swiper-pagination-bullet-active {
			&:before {
				background-color: rgb(var(--brand-dark-secondary));
			}
		}
	`,
};
