let viewSize = 'M';
setViewSize();
window.addEventListener('resize', setViewSize);

function setViewSize() {
	if (window.innerWidth <= 480) {
		viewSize = 'XXS';
	} else if (window.innerWidth <= 600) {
		viewSize = 'XS';
	} else if (window.innerWidth <= 768) {
		viewSize = 'S';
	} else if (window.innerWidth <= 960) {
		viewSize = 'L';
	} else if (window.innerWidth <= 1280) {
		viewSize = 'XL';
	} else {
		viewSize = 'XXL';
	}
}

export default function getViewSize() {
	return viewSize;
}
